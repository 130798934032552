import { InterfMenuList } from "./admin.menuList";

export const galpaoMenuList: InterfMenuList[] = [
  {
    menuList: [
      // { url: '/user/galpao', nome: 'Transportador', icon: 'domain', subMenu: [
      //   {url: '/user/galpao/galpao', nome: 'Transportador', icon: 'recycling'},
      //   {url: '/user/operacional/gravimetria', nome: "Estoque", icon: 'emoji_objects'},
      // ]},

      { url: '/user/dashboard2', nome: "Dashboard", icon: 'bar_chart' },
      { url: '/user/galpao/galpao', nome: 'Transportador', icon: 'recycling' },
      { url: 'http://easyquote.com.br/login', nome: 'Cotação', icon: 'mail', },
      { url: 'http://comunicacao.minhacoleta.com.br/', nome: 'Comunicação', icon: 'mail', },
      { url: '/user/operacional/gravimetria', nome: "Estoque", icon: 'emoji_objects' },
      { url: '/user/clientes/clientes', nome: "Gerador", icon: 'home_work' },
      { url: '/user/operacional/coletas/coletas', nome: "Coletas", icon: 'delete' },
      { url: '/user/galpao/motoristas', nome: 'Motoristas/Catadores', icon: 'badge_outline' },
      { url: '/user/contratarColetas/cadastre-plano', nome: "Cadastre Plano", icon: 'edit_square' },
      { url: '/user/financeiro/nota_fiscal', nome: "Nota Fiscal", icon: 'receipt' },
      { url: '/user/galpao/rotas', nome: "Rotas", icon: 'alt_route' },
      { url: '/user/industriaDestino', nome: 'Indústria/Receptor', icon: 'factory' },
      { url: '/user/controle-pesagem', nome: 'Controle de Peso Final', icon: 'scale' },

    ]
  }
]
