import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {DashboardFilterHelperService} from "../../services/dashboard-filter-helper/dashboard-filter-helper.service";
import {FormControl, FormGroup} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {ProgressLoader} from "../../../../../helpers/progress-loader";
import {setGraph} from "../../../dashboard/helpers/highcharts.helper";
import {addMessageCallbackColumn, removeMessageCallbackColumn} from "../../../../../helpers/events.helpers";
import {HttpService} from "../../../../../services/http-service/http.service";
import {Subscription} from "rxjs";
import {DgAutocomplete3Component} from "@datagrupo/dg-ng-util";
import {SessionService} from "../../../../../core/services/session-service/session.service";

@Component({
  selector: 'page-dashboard2-estoque',
  templateUrl: './dashboard2-estoque.component.html',
  styleUrls: ['./dashboard2-estoque.component.scss']
})
export class Dashboard2EstoqueComponent implements OnInit {

  @ViewChild('favoritos') favoritos!: DgAutocomplete3Component

  public formFilter = new FormGroup({
    favoritos: new FormControl(''),
    operadores: new FormControl([]),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    residuos: new FormControl([]),
    estados: new FormControl([]),
  })

  private listSubscriptionsHttpRequest: {
    [key: string]: Subscription | undefined,
  } = {}

  controlles: {
    // lidando com requests
    handleHequests: { [key: string]: Subscription | undefined },
    handleHequestsFilters: { [key: string]: Subscription | undefined },
    // lista de valores dos filtros
    filtersOptions: { [key: string]: any[] },
    // lista de graficos/requests que devem ser executados
    listLoaderPermission: string[],
    // lista de filtros que pode aparecer
    permissionFilters: string[],
    // controller de loader dos dados
    loaders: ProgressLoader.classe,
    // ultimos filtros buscados
    lastFilter: any
  } = {
    filtersOptions: {
      favoritos: [],
      operadores: [],
      tipoResiduos: [],
      estados: [],
    },
    handleHequests: {
      info: undefined,
      anos: undefined,
      meses: undefined,
      totais: undefined,
      tipoResiduos: undefined,
      motoristas: undefined,
    },
    handleHequestsFilters: {},
    listLoaderPermission: [],
    permissionFilters: ['tipoResiduos', 'transportadores', 'estados'],
    loaders: ProgressLoader.createLoader(),
    lastFilter: undefined
  }

  verifyShow: {
    showLoader: boolean,
    showPages: boolean,
    viewGraph: {[key: string]: 'graph' | 'table'}
  } = {
    showLoader: false,
    showPages: false,
    viewGraph: {
      estoqueRosca: 'graph',
      estoqueBarra: 'graph',
      comparativo: 'graph',
    }
  }

  dataDashboard: {
    dataGraphs: {
      estoqueRosca: any[],
      estoqueBarra: any[],
      comparativo: any[],
    }
  } = {
    dataGraphs: {
      estoqueRosca: [],
      estoqueBarra: [],
      comparativo: [],
    }
  }

  constructor(
    public filterHelpers: DashboardFilterHelperService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private http: HttpService,
    private session: SessionService
  ) {
    this.loaderFilterList()

    // this.loaderFavoritos()
    this.initPermissions()

    if (activatedRoute.snapshot.queryParams['estoque']) {
      const filtersParams = JSON.parse(activatedRoute.snapshot.queryParams['estoque'])
      this.formFilter.patchValue(filtersParams);
      this.initLoaderData()
    }
  }

  /**#################################################
   * ## INICIALIZAÇÃO E PERMISSAO
   #################################################*/

  ngOnInit(): void {
  }

  initPermissions() {
    if (this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO', 'PARCEIRO'])) {
      this.controlles.permissionFilters = ['favoritos', 'unidades', 'transportadores', 'tipoResiduos', 'motoristas', 'dataInicio', 'dataFim', 'estados']
      this.controlles.listLoaderPermission = ['ods', 'impacto', 'gri']
    }
    if (this.session.checkPerfil(['CASA', 'CONDOMINIO', 'EMPRESA', 'EVENTO'])) {
      this.controlles.permissionFilters = ['favoritos', 'transportadores', 'tipoResiduos', 'motorista', 'dataInicio', 'dataFim', 'estados']
      this.controlles.listLoaderPermission = ['ods', 'impacto', 'gri']
      this.formFilter.patchValue({
        unidades: [this.session.user.cliente_id]
      })
      this.changeFilters()
      this.initLoaderData()
    }
    if (this.session.checkPerfil(['GALPAO'])) {
      this.controlles.permissionFilters = ['unidades', 'tipoResiduos', 'dataInicio', 'dataFim', 'estados']
      this.controlles.listLoaderPermission = ['ods', 'impacto', 'gri']
    }
  }

  /**#################################################
   * ## FILTROS
   #################################################*/

    // requests dos filtros, foram criados dessa forma, pois são chamados em duas
    // condicionais diferentes. E dessa maneira podemos garantir um fluxo correto e editável de dados.
    // alem disso cada request deve tratar os dados de parametros da maneira que o back espera.
  private requestsFilters = {
    residuos: () => {
      if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
        this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
        this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
    },
    transportadores: () => {
      if (!!this.controlles.handleHequestsFilters['transportadores']) {
        this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
        this.controlles.handleHequestsFilters['transportadores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', params, list => this.controlles.filtersOptions['operadores'] = list)
    },
    estados: () => {
      if (!!this.controlles.handleHequestsFilters['estados']) {
        this.controlles.handleHequestsFilters['estados']?.unsubscribe();
        this.controlles.handleHequestsFilters['estados'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)

      if (params['operadores']) {
        params['galpaoId'] = params['operadores'];
        delete params['operadores'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('estados', params, list => this.controlles.filtersOptions['estados'] = list)
    },
  }

  loaderFilterList(filterList?: string) {
    if (!filterList) {
      if (this.controlles.permissionFilters.includes('tipoResiduos')) this.requestsFilters.residuos()
      if (this.controlles.permissionFilters.includes('transportadores')) this.requestsFilters.transportadores()
      if (this.controlles.permissionFilters.includes('estados')) this.requestsFilters.estados()

      return
    }

    const mapAtualisacoes: { [key: string]: string[] } = {
      transportadores: ['tipoResiduos', 'estados'],
      tipoResiduos: ['transportadores', 'estados'],
      estados: ['transportadores', 'tipoResiduos'],
    }

    if (!this.controlles.permissionFilters.includes(filterList)) return;
    if (!(filterList in mapAtualisacoes)) return;

    if (mapAtualisacoes[filterList].includes('transportadores')) {
      this.requestsFilters.transportadores()
    }

    if (mapAtualisacoes[filterList].includes('tipoResiduos')) {
      this.requestsFilters.residuos()
    }

    if (mapAtualisacoes[filterList].includes('estados')) {
      this.requestsFilters.estados()
    }
  }

  loaderFavoritos() {
    const paramsLoaderFavoritos:{[key:string]: any} = this.filterHelpers.getFormFilterValues(this.formFilter, true);

    this.filterHelpers.loaderListFavoritos('estoque', paramsLoaderFavoritos, list => this.controlles.filtersOptions['favoritos'] = list)
  }

  changeFilters(nameFilter?: string) {
    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter);

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {estoque: Object.keys(queryParams).length > 0 ? JSON.stringify(queryParams) : null},
        queryParamsHandling: 'merge',
      }
    ).then();
    // if (!!nameFilter) this.loaderFilterList(nameFilter)
    this.loaderFilterList(nameFilter)
  }

  clearFilters() {
    this.formFilter.patchValue({
      favoritos: '',
      operadores: [],
      dataInicio: '',
      dataFim: '',
      residuos: [],
      estados: [],
    })
    this.filterHelpers.clearRequests(this.controlles.handleHequestsFilters)
    this.changeFilters()
  }

  propagarFavorito() {
    const favoritoValues = this.favoritos.selectedList[0]

    if (!favoritoValues) return;

    this.formFilter.patchValue({
      unidades: (favoritoValues?.clientes || []).map((item: any) => item.id),
      operadores: (favoritoValues?.galpoes || []).map((item: any) => item.id),
      motoristas: (favoritoValues?.motoristas || []).map((item: any) => item.id),
      residuos: (favoritoValues?.tipoLixos || []).map((item: any) => item.id),
      dataInicio: favoritoValues?.dataInicio,
      dataFim: favoritoValues?.dataFim,
      estados: (favoritoValues?.estados || []).map((item: any) => item.uf),
    })

    this.changeFilters()
  }

  /**#################################################
   * ## DADOS
   #################################################*/

  initLoaderData() {
    if (JSON.stringify(this.controlles.lastFilter) == JSON.stringify(this.filterHelpers.getFormFilterValues(this.formFilter))) {
      return;
    }

    this.clearData()
    this.controlles.lastFilter = this.filterHelpers.getFormFilterValues(this.formFilter);

    if (Object.keys(this.filterHelpers.getFormFilterValues(this.formFilter)).length <= 0) {
      this.controlles.loaders.register([])
      return
    }

    const params = this.filterHelpers.getFormFilterValues(this.formFilter, true);
    this.filterHelpers.clearRequests(this.controlles.handleHequests)
    removeMessageCallbackColumn(['dashboard-estoque'])

    this.controlles.loaders.register(['estoque', 'comparativo'])

    this.listSubscriptionsHttpRequest['estoque'] = this.http.get('dashboard/estoque', {params}, true).subscribe(
      resp => {

        if (resp?.data?.charts?.length <= 0) {
          this.controlles.loaders.finish('estoque', true)
          addMessageCallbackColumn({
            title: 'Estoque: Gráficos de estoque',
            message: 'Dados insuficientes para gerar os graficos de estoque',
            keys: ['dashboard-estoque', 'estoque'],
          })
          return
        }

        this.verifyShow.showPages = true;
        this.controlles.loaders.finish('estoque')

        setGraph('id-chart-estoque-barra', resp.data.charts[0])
        this.dataDashboard.dataGraphs.estoqueRosca = this.filterHelpers.extractChartTipoResiduos({charts: [resp.data.charts[0]]})

        setGraph('id-chart-estoque-rosca', resp.data.charts[1])
        this.dataDashboard.dataGraphs.estoqueBarra = this.filterHelpers.extractChartData(resp.data.charts[1])

      },
      error => {
        this.controlles.loaders.finish('estoque', true)
        addMessageCallbackColumn({
          status: 'warning',
          title: 'Estoque: Erro ao gerar relatórios',
          message: 'Erro ao gerar relatório de Meses',
          keys: ['dashboard-estoque', 'meses'],
        })
      }
    )
    this.listSubscriptionsHttpRequest['comparativo'] = this.http.get('dashboard/estoque-comparativo').subscribe(
      resp => {

        if (resp?.data?.charts?.length <= 0) {
          this.controlles.loaders.finish('comparativo', true)
          addMessageCallbackColumn({
            title: 'Estoque: Gráfico comparativo',
            message: 'Dados insuficientes para gerar os graficos de estoque',
            keys: ['dashboard-estoque', 'estoque'],
          })
          return
        }
        this.verifyShow.showPages = true;
        this.controlles.loaders.finish('comparativo')

        setGraph('id-chart-estoque-comparativo', resp.data.charts[0])
        this.dataDashboard.dataGraphs.comparativo = this.filterHelpers.extractChartData(resp.data.charts[0])
      },
      error => {
        this.controlles.loaders.finish('comparativo', true)
        addMessageCallbackColumn({
          status: 'warning',
          title: 'Estoque: Erro ao gerar relatórios',
          message: 'Erro ao gerar relatório de Meses',
          keys: ['dashboard-estoque', 'meses'],
        })
      }
    )
    this.listSubscriptionsHttpRequest['ods'] = this.http.get('dashboard/indicador-ods').subscribe(
      resp => {

        if (resp?.data?.charts?.length <= 0) {
          this.controlles.loaders.finish('ods', true)
          addMessageCallbackColumn({
            title: 'Erro ao gerar ods',
            message: 'Dados insuficientes para gerar os graficos de estoque',
            keys: ['dashboard-estoque', 'estoque'],
          })
          return
        }
        this.verifyShow.showPages = true;
        this.controlles.loaders.finish('ods')
      },
      error => {
        this.controlles.loaders.finish('ods', true)
        addMessageCallbackColumn({
          status: 'warning',
          title: 'Estoque: Erro ao gerar ods',
          message: 'Erro ao gerar relatório de Meses',
          keys: ['dashboard-estoque', 'meses'],
        })
      }
    )
  }

  clearData() {
    this.verifyShow = {
      showLoader: false,
      showPages: false,
      viewGraph: {
        estoqueRosca: 'graph',
        estoqueBarra: 'graph',
        comparativo: 'graph',
      }
    }
  }

}
