import { HostListener, Injectable } from '@angular/core';
import { InterfMenuList } from "../../config/menu/admin.menuList";
import { MenuService } from "../menu/menu.service";
import { TokenService } from "../token-service/token.service";

interface InterceUser {
  id?: number | string
  perfil?: 'CONDOMINIO' | 'ADMINISTRADOR' | 'MOTORISTA' | 'GALPAO' | 'ADMINISTRATIVO'
  | 'PARCEIRO' | 'EMPRESA' | 'EVENTO' | 'CASA',
  cliente_id?: number
  galpao_id?: number,
  parceiro_id?: number,
  galpao_nome?: string,
  cliente_nome?: string,
  profile?: tiposProfile
}

declare type tiposProfile = 'SINDICO' | 'CONSELHO' | 'MORADOR' | 'PORTEIRO' | 'FINANCEIRO' | 'HOLDING';

declare type tiposPerfil = 'CONDOMINIO' | 'ADMINISTRADOR' | 'MOTORISTA' | 'GALPAO' | 'ADMINISTRATIVO'
  | 'PARCEIRO' | 'EMPRESA' | 'EVENTO' | 'CASA';

@Injectable({
  providedIn: 'root'
})
export class SessionService {

  public user: InterceUser = {};
  public pontuacao: number | undefined
  //Variavel de controle de exibição do alerta de perguntas no header, caso não tenham sido respondidas.
  public hasRespostas: boolean | undefined;

  constructor(
    public menu: MenuService,
    private token: TokenService
  ) {
  }

  @HostListener('window:sistem-logout', ['$event'])
  public logout() {
    window.localStorage.clear();
    window.location.href = `${window.location.origin}/login`;
  }

  setUser() {
    let token: any = this.token.abrirToken();
    this.user.id = token.sub;
    this.user.perfil = token.perfil;
    this.user.cliente_id = token.cliente_id;
    this.user.galpao_id = token.galpao_id;
    this.user.parceiro_id = token.parceiro_id;
    this.user.galpao_nome = window.localStorage.getItem('galpao_nome') || 'gerador não encontrada';
    this.user.profile = token['user-profile']

    if (!!window.localStorage.getItem('pontuacao')) {
      this.pontuacao = Number(window.localStorage.getItem('pontuacao'));
    }
  }

  public checkPerfil(perfil: tiposPerfil | (tiposPerfil)[]) {
    if (typeof perfil === 'string') {
      return this.user.perfil === perfil

    } else if (Array.isArray(perfil)) {
      return perfil.findIndex(perfil => perfil === this.user.perfil) !== -1
    }

    return false
  }

  public checkProfile(profile: tiposProfile | (tiposProfile)[]) {
    if (typeof profile === 'string') {
      return this.user.profile === profile

    } else if (Array.isArray(profile)) {
      return profile.findIndex(prof => prof === this.user.profile) !== -1
    }

    return false
  }

  public checkModules(module: "FINANCEIRO" | "EDUCACAO_AMBIENTAL" | "COMUNICACAO" | "LOGISTICA_REVERSA"):boolean {
    return (this.token.abrirToken()?.modulos || []).includes(module)
  }
}
