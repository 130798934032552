import {Component, OnInit, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GenericCrudService} from "../../../../../core/services/generic-crud-service/generic-crud.service";
import {InputHelpers} from "../../../../../helpers/input.helpers";
import {COLETA_PESO} from "../../../../../core/config/endpoint-list";
import {dispacherAlterTable} from "../../../../../helpers/helpers";

@Component({
  selector: 'modal-controle-pesagem',
  templateUrl: './modal-controle-pesagem.component.html',
  styleUrls: ['./modal-controle-pesagem.component.scss']
})
export class ModalControlePesagemComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;

  public dataColeta = '';
  public motorista = '';

  form = new FormGroup({
    id: new FormControl(''),
    pesoCaminhaoSaida: new FormControl('', [Validators.required]),
    pesoCaminhaoChegada: new FormControl('', [Validators.required]),
  })

  constructor(public service: GenericCrudService) {
    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['pesoCaminhaoSaida', 'pesoCaminhaoChegada'])
    )
  }

  ngOnInit(): void {
  }

  open(val?: any) {
    if (!!val) {
      this.motorista = val.motoristaNome.nome;
      this.dataColeta = val.dataColeta;
      this.service.get(COLETA_PESO + (val.rotaId || val.id)).subscribe(
        resp => {
          this.form.patchValue({
            ...resp.data,
            pesoCaminhaoSaida: (InputHelpers.initInputDecimal(resp.data.pesoCaminhaoSaida) || '') || resp.data.pesoCaminhaoSaida,
            pesoCaminhaoChegada: (InputHelpers.initInputDecimal(resp.data.pesoCaminhaoChegada) || '') || resp.data.pesoCaminhaoChegada,
          })

          this.modal.open();
        }
      )
    } else {
      this.modal.open()
    }
  }

  public close = () => {
    this.form.reset('')
  }

  save() {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      return;
    }

    const { pesoCaminhaoSaida, pesoCaminhaoChegada, ...form } = this.form.value

    const data = {
      ...form,
      pesoCaminhaoSaida: Number(InputHelpers.ajusteMoneyBrToEua(pesoCaminhaoSaida)),
      pesoCaminhaoChegada: Number(InputHelpers.ajusteMoneyBrToEua(pesoCaminhaoChegada)),

    }

    this.service.put(COLETA_PESO, data).subscribe(() => {
      dispacherAlterTable('tableColetas')
      this.modal.close();
    })
  }


  getPesoTotal(): number {
    const form = this.form.value;

    return (((Number(InputHelpers.ajusteMoneyBrToEua(form.pesoCaminhaoChegada))) || 0) - (Number(InputHelpers.ajusteMoneyBrToEua(form.pesoCaminhaoSaida)) || 0))
  }

  format = (val: string | number) => {
    return Number(val).toLocaleString("pt-BR", {
      minimumFractionDigits: 3,
      maximumFractionDigits: 3
    });
  }
}
