import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AbstractInsertEdit,
  CrudDataTableComponent,
  crudDispatchEvent,
  HttpClient,
  InsertEditConfig,
  InterfaceCustomActions
} from "@datagrupo/dg-crud";
import { TipoLixoEntity } from "../tipo-lixo.entity";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { environment } from "../../../../../environments/environment";
import { TIPO_LIXO } from "../../../../core/config/endpoint-list";
import { TipoLixoCompostoEntity } from "../tipo-lixo-composto.entity";
import { TipoLixoFilhoComponent } from "../subComponents/tipo-lixo-filho/tipo-lixo-filho.component";
import Swal from "sweetalert2";
import { InputHelpers } from 'src/app/helpers/input.helpers';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { GrupoMercadoEntity } from '../grupo-merccado.entity';
import { SegmentoMercadoMercadoEntity } from '../segmento-mercado.entity';
import { SegmentosOperacionaisEntity } from '../segmentos-operacionais.entity';
import { InputAutocompleteComponent } from '@datagrupo/dg-ng-util';
import {HttpService} from "../../../../services/http-service/http.service";
import {UnidadeMedidaEntity} from "../../unidade-medida/unidade-medida.entity";

@Component({
  selector: 'app-tipo-lixo-insert-edit',
  templateUrl: './tipo-lixo-insert-edit.component.html',
  styleUrls: ['./tipo-lixo-insert-edit.component.scss']
})
export class TipoLixoInsertEditComponent extends AbstractInsertEdit<TipoLixoEntity> implements OnInit {

  @ViewChild('modal') modal!: TipoLixoFilhoComponent;
  @ViewChild('crudDataTable') crudTable!: CrudDataTableComponent;
  // @ViewChild('selectedCodigosConama') selectedCodigosConama!: InputAutocompleteComponent;


  public listLixo: [] = []
  public listUnidadesMedidas: UnidadeMedidaEntity[] = []
  public listClasse: [] = []
  public listSegmentosOperacionais: any[] = []
  public listSegmentosMercado: [] = []
  public listGruposMercado: [] = []
  public listCodConama: any[] = []

  public form = new FormGroup({
    nome: new FormControl('', [Validators.required]),
    categoria: new FormControl('', [Validators.required]),
    codigo: new FormControl('', [Validators.required]),
    // unidadesMedida: new FormControl([], [Validators.required]),
    densidade: new FormControl(''),
    grupoMercado: new FormControl(''),
    segmentoMercado: new FormControl({ disabled: true, value: '' }),
    segmentosOperacionais: new FormControl({ disabled: true, value: '' }),
    referencia: new FormControl(''),
    observacoes: new FormControl(''),
    codigosConama: new FormControl([]),
    codigoOnu: new FormControl(''),
    classesResiduos: new FormControl(''),
    detalhamentoDescritivo: new FormControl('')
  });

  entityTipoLixo = new TipoLixoCompostoEntity();
  customActionsLixoComposto: InterfaceCustomActions[] = [
    {
      name: 'Editar',
      action: (row: TipoLixoCompostoEntity) => {
        this.modal.open(row);
      }
    },
    {
      name: 'Excluir',
      action: (row: TipoLixoCompostoEntity) => {
        this.excluirLixoComposto(row)
      }
    }
  ]

  constructor(
    public config: InsertEditConfig,
    public http: HttpService,
    public session: SessionService
  ) {
    super(config, { path: environment.apiUrl, context: TIPO_LIXO });

    this.crudConfig.subscribeEntityAfterSave = false

    this.form.valueChanges.subscribe(
      v => InputHelpers.ajusteInputDecimal(this.form, v, ['densidade'])
    )

    this.http.get('tipo-lixo/present-on-cliente-container').subscribe((resp) => {
      //@ts-ignore
      this.listLixo = resp.data
    })
    this.http.get('grupos-mercado', { params: { unpaged: true } }).subscribe((resp) => {
      //@ts-ignore
      this.listGruposMercado = resp.data
    })
    this.http.get('classes-residuos', { params: { unpaged: true } }).subscribe((resp) => {
      //@ts-ignore
      this.listClasse = resp.data
    })
    this.http.get('conamas', { params: { unpaged: true } }).subscribe((resp) => {
      //@ts-ignore
      this.listCodConama = resp.data
    })
    this.http.get('unidade-medida', { params: {unpaged: true}  }).subscribe(resp => {
      this.listUnidadesMedidas = resp.data || [];
    })

    if(this.session.checkPerfil(['ADMINISTRATIVO'])){

      this.form.controls['codigo'].disable();
      this.form.controls['nome'].disable();
      this.form.controls['classesResiduos'].disable();
      this.form.controls['categoria'].disable();
      this.form.controls['densidade'].disable();
    }
  }

  override ngOnInit(): void {
    super.ngOnInit();
  }

  initNewEntity(): void {
    this.entity = new TipoLixoEntity()
  }
  preSelectedConama : any[] = [];
  preSelectedGrupMercado: GrupoMercadoEntity[] = [];
  preSelectedSegmentosOperacionais : SegmentosOperacionaisEntity[] = [];
  preSelectedSegmentoMercado: SegmentoMercadoMercadoEntity[] = [];

  override afterFetchEntity() {

    const listGrupMercado: GrupoMercadoEntity[] = [];
    const listSegmentoMercado: SegmentoMercadoMercadoEntity[] = [];
    const listSegmentoOeracionais = this.entity.segmentosOperacionais || [];

    listSegmentoOeracionais.map((item: SegmentosOperacionaisEntity) => {
      if (item.segmentoMercado?.grupoMercado?.id) {
        if (listGrupMercado.findIndex(item2 => item2?.id == item.segmentoMercado?.grupoMercado?.id) == -1) {
          listGrupMercado.push(item.segmentoMercado.grupoMercado)
        }
      }

      if (item.segmentoMercado?.id) {
        if (listSegmentoMercado.findIndex(item2 => item2?.id == item.segmentoMercado?.id) == -1) {
          listSegmentoMercado.push(item.segmentoMercado)
        }
      }
    })

    this.preSelectedSegmentoMercado = listSegmentoMercado || [];
    this.preSelectedSegmentosOperacionais = listSegmentoOeracionais || [];
    this.preSelectedGrupMercado = listGrupMercado || [];

    const segmentosOperacionaisResult = (this.entity.segmentosOperacionais || [])[0];
    const segmentoMercadoResult = segmentosOperacionaisResult?.segmentoMercado;
    const grupoMercadoResult = segmentoMercadoResult?.grupoMercado


    if(!!segmentosOperacionaisResult?.id){
      this.http.get('segmentos-operacionais', { params: { unpaged: true, segmentosMercadoId: segmentoMercadoResult?.id || '' } }).subscribe((resp) => {
        //@ts-ignore
        this.listSegmentosOperacionais = resp.data
      })

      this.http.get('segmentos-mercado', { params: { unpaged: true, gruposMercadoId: [grupoMercadoResult?.id || '' ]} }).subscribe((resp) => {
        //@ts-ignore
        this.listSegmentosMercado = resp.data
      })
    }


    this.form.patchValue({
      ...this.entity,
      codigosConama: this.entity.codigosConama?.map((item: any)=> item.id) || [],
      classesResiduos: this.entity.classesResiduos?.map((item: any)=> item.id) || [],
      densidade: InputHelpers.initInputDecimal(this.entity.densidade || '') || this.entity.densidade,
      unidadesMedida: (this.entity?.unidadesMedida || []).map(item => item.id).filter(item => !!item),
    })

    if(this.entityId && !!this.entity.densidade){
      this.form.controls['densidade'].disable();
    }
  }

  override beforeSaveEntity(): boolean {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.callbackMessages.errorAfterSave = {
        showMessage: true,
        title: 'Preencha os campos obrigatórios',
        text: 'Um ou mais campos não foram preenchidos corretamente.',
        confirmButtonText: 'Fechar',
        timer: 5000,
        customClass: ''
      }
      return false;
    }

    if (!!this.entity?.id && (this.crudTable.data || []).length > 0) {
      let porcentagemTotal = 0;

      this.entity.listTipoLixoComposto.map(tipoL => {
        if (!tipoL.dataExclusao) {
          porcentagemTotal = porcentagemTotal + Number(tipoL.porcentagemFilho)
        }
      })

      if (porcentagemTotal != 100) {
        this.callbackMessages.errorAfterSave = {
          showMessage: true,
          title: 'Porcentagem é diferente de 100',
          text: 'O total da porcentagem não pode ser diferente de 100',
          timer: 5000,
          confirmButtonText: 'Fechar',
          customClass: ''
        }
        return false;
      }
    }

    const {
      classesResiduos, segmentosOperacionais, segmentoMercado, grupoMercado, densidade,
      unidadesMedida, codigosConama, ...form } = this.form.value;

    // const index = this.listSegmentosOperacionais.findIndex((resp: any) => segmentosOperacionais == resp.id);
    // if (index == -1) {
    //   return false;
    // }

    // const dataSegmento: any[] = [
    //   {
    //     ...this.listSegmentosOperacionais[index] || {}
    //   }
    // ];

    // const resultUnidadesMedida = (unidadesMedida || []).map((item: string) => ({ id: item, nome: '--' }));
    // const resultUnidadesMedida: any[] = [];
    // unidadesMedida.map((unidadeSelected: string) => {
    //   const unidade = this.listUnidadesMedidas.find(item => item.id == unidadeSelected)
    //
    //   if (!!unidade) {
    //     resultUnidadesMedida.push(unidade);
    //   }
    // })

    console.log(codigosConama);
    debugger

    const resultCodigosConama = (codigosConama || []).map((item: string) => ({ id: item }))

    this.entity = {
      ...this.entity,
      ...form,
      codigosConama: resultCodigosConama,
      // unidadesMedida: resultUnidadesMedida,
      densidade: !!this.entity.id ? this.entity.densidade : InputHelpers.ajusteMoneyBrToEua(densidade),
    }
    return true;
  }

  override afterSaveEntity() {
    if (!Array.isArray(this.entity.listTipoLixoComposto)) {
      this.entity['listTipoLixoComposto'] = []
    }
  }

  excluirLixoComposto(data: TipoLixoCompostoEntity) {
    if (!!data?.id) {
      const index = this.entity.listTipoLixoComposto.findIndex(tipoL => tipoL.id == data.id);

      if (index != -1) {
        this.entity.listTipoLixoComposto[index].dataExclusao =
          new Date().toLocaleDateString().split('/').reverse().join('-') +
          ' ' +
          new Date().toLocaleTimeString('pt-BR', { timeZone: 'UTC' });

        crudDispatchEvent('tipoLixoComposto')
      }
    } else {
      const index = this.entity.listTipoLixoComposto?.findIndex(tipoLixo => tipoLixo.tipoLixoFilho?.id == data?.tipoLixoFilho?.id);

      if (index != -1) {
        this.entity.listTipoLixoComposto.splice(index, 1);

        crudDispatchEvent('tipoLixoComposto')
      }
    }
  }

  selecctedGrupoMercado(val: any) {
    if ((val.selectedList || []).length == 0) {
      this.form.controls['segmentoMercado'].disable();
      return
    }

    const gruposMercadoId = val.selectedList.map((item: any) => item.id)

    this.http.get('segmentos-mercado', { params: { unpaged: true, gruposMercadoId } }).subscribe((resp) => {
      //@ts-ignore
      this.listSegmentosMercado = resp.data
      this.form.controls['segmentoMercado'].enable();
    })
  }

  segmentoMercado(val: any) {
    if ((val.selectedList || []).length == 0) {
      this.form.controls['segmentosOperacionais'].disable();
      return
    }

    const segmentosMercadoId = val.selectedList.map((item: any) => item.id)

    this.http.get('segmentos-operacionais', { params: { unpaged: true, segmentosMercadoId } }).subscribe((resp) => {
      //@ts-ignore
      this.listSegmentosOperacionais = resp.data
      this.form.controls['segmentosOperacionais'].enable();
    })
  }

  selectSegmentosOperacionais(val: any) {
    this.entity.segmentosOperacionais = val.selectedList
  }

  selectClassesResiduos(val: any) {
    this.entity.classesResiduos = val.selectedList
  }

  getNomeCoresConama(codConama: string) {
    const nomesByCodigos: {[key:string]:string} = {
      "#B7B7B7": 'CINZA',
      "#FF6D01": 'LARANJA',
      "#EA4335": 'VERMELHO',
      "#0000FF": 'AZUL',
      "#FBBC04": 'AMARELO',
      "#000000": 'PRETO',
      "#34A853": 'VERDE',
      "#783F04": 'MARROM'
    }

    if (codConama in nomesByCodigos) {
      return nomesByCodigos[codConama];
    }

    return codConama;
  }

}
