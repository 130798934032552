import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {CrudDataTableComponent, DataServer, InterfaceCustomActions} from '@datagrupo/dg-crud';
import {SessionService} from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';
import {ColetasEntity} from "../coletas.entity";
import {ColetasService} from "../services/coletas.service";
import {ContainerClienteEntity} from "../../../_clientes/_entitys/container-cliente.entity";
import {environment} from "../../../../../../environments/environment";
import {ColetaPendenteEntity} from "../coleta-pendente.entity";
import {CdkDgTable, CdkDynamicTable, CdkDynamicTableService} from "@datagrupo/dg-ng-util";
import {HttpService} from "../../../../../services/http-service/http.service";
import {helpersTableMTR, MtrEntity} from "../mtr.entity";

class ContainerColetaMensal extends ContainerClienteEntity {
  selected: boolean = false;
  hasMtr: boolean = false;
  pesoMtr: string = '';
}

@Component({
  selector: 'app-coletas-main',
  templateUrl: './coletas-main.component.html',
  styleUrls: ['./coletas-main.component.scss']
})
export class ColetasMainComponent implements OnInit {

  public tableVisible: string = 'coletas';

  tableMTR: CdkDynamicTable.tableClass;
  helpersTableMTR = helpersTableMTR(this.http, this.session)

  public mapEntity = new ColetasEntity();
  public mapEntityCanceladas = new ColetasEntity();
  @ViewChild('crudTable') crudTable!: CrudDataTableComponent
  public mapEntityPendentes = new ColetaPendenteEntity();

  public customAction: InterfaceCustomActions[] = [
    {
      name: 'Editar',
      action: (val: ColetasEntity) => {
        this.route.navigate(['user/operacional/coletas/coletas/' + val.id]).then()
      },
      permission: this.session.checkProfile(['SINDICO', 'CONSELHO']) || this.session.checkPerfil(['GALPAO', 'ADMINISTRADOR', 'PARCEIRO'])
    },
    {
      name: 'Excluir',
      action: (val: ColetasEntity) => {
        Swal.fire({
          icon: 'warning',
          title: "Excluir registro",
          text: 'Deseja excluir este registro? Esta ação é permanente.',
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          confirmButtonColor: 'red',
          cancelButtonColor: 'blue',
          cancelButtonText: 'Cancelar'
        }).then(confirm => {
          if (confirm.isConfirmed) {
            this.service.patch('coletas/' + val.id + '/data-cancelamento', {}).subscribe(() => this.crudTable._pesquisar())
            Swal.fire({
              title: 'Deletado com sucesso',
              text: 'Esse registro foi deletado com sucesso',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            })
          }
        })
      },
      permission: (val: ColetasEntity) => {
        return (val.status == 'ABERTO' || !val.status) && !this.session.checkPerfil(['GALPAO', 'PARCEIRO']);
      }
    },
    {
      name: 'Verificar requisítos MTR',
      action: (row: ColetasEntity) => {
        if (!row.id) return;
        this.verifyMTRColeta(row.id)
      },
      permission: true
    }
  ]

  constructor(
    public service: ColetasService,
    public session: SessionService,
    public route: Router,
    private createTable: CdkDynamicTableService,
    private http: HttpService
  ) {
    this.mapEntityPendentes.addActions(<InterfaceCustomActions[]>[
      {
        name: 'excluir',
        action: () => {
        },
        permission: false
      },
      {
        name: 'editar',
        action: () => {
        },
        permission: false
      },
      {
        name: 'Criar rota para esse dia',
        action: (val: ColetaPendenteEntity) => {
          this.route.navigate(['/user/galpao/rotas/novo_pendente'], {queryParams: {data: val.dataColeta}}).then()
        }
      }
    ])
    this.mapEntity.configEntityTable.where = {isCancelada: false}
    this.mapEntityCanceladas.configEntityTable.where = {isCancelada: true}
    this.mapEntityCanceladas.addActions([
      {
        name: 'editar', action: () => {
        }, permission: false
      },
      {
        name: 'excluir', action: () => {
        }, permission: false
      },
    ])

    this.tableMTR = this.createTable.createByEntity(new MtrEntity())

    // this.tableMTR.controls.data.set([
    //   {
    //     "id": 26,
    //     "mtrCodigo": null,
    //     "coletaId": 234141,
    //     "clienteId": 100126,
    //     "responseBody": "{\"respostaApiwsManifestoDTO\":null,\"erroNacional\":true,\"mensagemErroNacional\":null}",
    //     "descricaoErro": "JSON format error: respostaApiwsManifestoDTO is not an array or is empty",
    //     "status": "ERRO",
    //     "dataInclusao": 1725122898909
    //   },
    // ])
    this.tableMTR.find();

    if (session.checkPerfil(['EVENTO', 'GALPAO', 'EMPRESA', 'CONDOMINIO'])) {
      this.mapEntity.removeFilters(['cliente'])
    }

  }

  ngOnInit(): void {
  }

  public listContainers: ContainerColetaMensal[] = [];

  public selectContainer: { id: string | number, mtr: boolean, peso: string }[] = [];
  public contSelect: (number | string)[] = [];
  public mtrSelect: (number | string)[] = [];

  private verifyMTRColeta(coletaId: string | number) {
    this.http.get('/mtr/validate/coleta/'+coletaId).subscribe(
      (resp: any) => {
        Swal.fire({
          icon: 'question',
          title: 'Dados do MTR',
          text: resp.data
        }).then()
      }
    )
  }

  resourceTableMtr(item: any, column: CdkDgTable.column) {
    debugger
    if (!(<string>(typeof column == 'string' ? column : column.name) in item)) return '--'

    if (typeof column == 'string') {
      return item[column] || '--';
    }

    if (!!column?.resource) {
      // @ts-ignore
      return column.resource(item[column?.name || ''], item)
    }

    return '--'
  }
}
