<div
  id="industria-destino-main"
  class="container"
>
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Destinador</h4>
        </div>
        <div class="card-subtitle">
          <filter-search
            [entityMap]="mapEntity"
            [table]="tableIndustriaDestino"
            tableEventId="industriaDestinoTable"
          ></filter-search>
        </div>
        <div
          class="card-body"
          style="padding: 0"
        >
          <crud-data-table
            #tableIndustriaDestino
            [entityMap]="mapEntity"
            [service]="service"
            [showPagination]="false"
            [customDblclick]="!!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? false : true"
            [customActions]="customAction"
            [context]="!!session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? 'industria' : 'industria/galpao' "
            tableEventId="industriaDestinoTable"
            [register]="true"
          ></crud-data-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-pagination
            [dataPagination]="mapEntity.configEntityTable.pagination"
            (changePage)="tableIndustriaDestino.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>
</div>


<page-footer>
  <a routerLink="novo">
    <button class="btn btn-success">Novo</button>
  </a>
</page-footer>
