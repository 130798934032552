import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {OldModalComponent} from "../../../../../../shared/ui/modal/modal/modal.component";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TIPO_LIXO, TIPO_LIXO_GALPAO} from "../../../../../../core/config/endpoint-list";
import {TipoLixoEntity} from "../../../../tipo-lixo/tipo-lixo.entity";
import {GenericCrudService} from "../../../../../../core/services/generic-crud-service/generic-crud.service";
import {crudDispatchEvent} from "@datagrupo/dg-crud";

@Component({
  selector: 'subComponent-galpao-tipo-lixo',
  templateUrl: './galpao-tipo-lixo.component.html',
  styleUrls: ['./galpao-tipo-lixo.component.scss']
})
export class GalpaoTipoLixoComponent implements OnInit {

  @ViewChild('modal') modal!: OldModalComponent;
  @Input() idGalpao: string | number | undefined;

  public tipoLixo = new FormControl('', [Validators.required]);

  public listTipoLixo: TipoLixoEntity[] = [];

  constructor(private service: GenericCrudService) {
    service.alterResource(TIPO_LIXO_GALPAO)
  }

  teste: any;

  ngOnInit(): void {}

  salvar() {
    if (this.tipoLixo.invalid) {
      this.tipoLixo.markAllAsTouched();
    }

    const data = {
      galpaoId: this.idGalpao,
      tipoLixoId: this.tipoLixo.value
    }

    this.service.saveOrUpdate(data).subscribe(
      resp => {
        crudDispatchEvent('GalpaoTipoLixoEntity');
        this.modal.close();
      }
    )
  }

  open() {
    this.service.get(TIPO_LIXO, { params: { unpaged: true } }).subscribe(
      resp => {
        this.listTipoLixo = resp.data;

        this.modal.open();
      }
    )
  }

  close() {
    this.modal.close();
  }

  afterClose = () => {
    this.tipoLixo.reset('')
  }
}
