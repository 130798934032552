import {InterfaceMenuList} from "../../../layouts/user/components/navigation/navigation.component";

export interface InterfMenuList {
  hrStart?: boolean,
  hrEnd?: boolean,
  menuList: InterfaceMenuList[]
}

export const adminMenuList: InterfMenuList[] = [
  {
    menuList: [
      {icon: 'desktop_windows', url: '/user', nome: 'Início',},

      {
        url: '/user/clientes', nome: "Gerador", icon: 'home_work', subMenu: [
          {url: '/user/clientes/clientes', nome: "Gerador", icon: 'home_work'},
          {url: '/user/clientes/funcionarios', nome: "Usuários", icon: 'account_circle'}
        ]
      },
      {
        url: '/user/galpao', nome: 'Transportador', icon: 'domain', subMenu: [
          {url: '/user/galpao/galpao', nome: 'Transportador', icon: 'recycling'},
          {url: '/user/galpao/motoristas', nome: 'Motoristas/Catadores', icon: 'badge_outline'},
          {url: '/user/galpao/arquivos', nome: 'Arquivos Do Transportador', icon: 'assignment'},
          {url: '/user/galpao/arquivos-motoristas', nome: 'Arquivos De Motorista/Catador', icon: 'assignment'},
          {url: '/user/galpao/rotas', nome: "Rotas", icon: 'alt_route'},

        ]
      },
      {
        url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory', subMenu: [
          {url: '/user/industriaDestino', nome: 'Destinador', icon: 'factory'},
          {url: '/user/controle-pesagem', nome: 'Controle de Peso Final', icon: 'scale'},
          {url: '/user/operacional/coleta-tratamento', nome: 'Tratamento', icon: 'filter_alt'},
          {url: '/user/operacional/gravimetria', nome: "Estoque", icon: 'emoji_objects'},
        ]
      },
      {
        url: '/user/logisticaReversa', nome: 'Logistica Reversa', icon: 'recycling', subMenu: [
          {url: '/user/logisticaReversa/logisticaReversa', nome: "Ponto de coleta", icon: 'published_with_change'},
          {
            url: '/user/logisticaReversa/pedidoCreditoLogisticaReversa',
            searchName: "Pedido Crédito Logistica Reversa",
            nome: "Pedido CLR",
            icon: 'attach_money'
          },
          {url: '/user/logisticaReversa/vendasLogisticaReversa', nome: 'Venda Logística Reversa', icon: 'autorenew'},
          {
            url: '/user/logisticaReversa/gestao-programa-logistica-reversa',
            nome: 'Gestão Programa Logística Reversa',
            icon: 'emoji_objects'
          },
          {url: '/user/logisticaReversa/relatorioMissoes', nome: 'Relatório das Missões', icon: 'assignment'},
        ]
      },
      {
        url: '/user/parceiros', icon: 'handshake', nome: 'Perfis Master', subMenu: [
          {url: '/user/parceiros/parceiro', nome: "Master", icon: 'group'},
          {url: '/user/parceiros/categoriaNegocio', nome: 'Categoria Negócio', icon: 'straight'},
          {url: '/user/parceiros/material', nome: 'Produto/Serviço', icon: 'panorama'}
        ]
      },
      {
        url: "/user/operacional", icon: 'settings', nome: 'Operacional', subMenu: [
          {url: '/user/operacional/container', nome: "Recipiente de Armazenagem", icon: 'inventory_2'},
          {url: '/user/operacional/coletas/coletas', nome: "Coletas", icon: 'delete'},
          {url: '/user/operacional/produto', nome: 'Produto(LR)', icon: 'categoryoutlined'},
          {url: '/user/operacional/funcionario', nome: 'Usuários', icon: 'co_present'},
          {url: '/user/operacional/categoria-arquivo', nome: 'Tipos de Documentos', icon: 'menu'},
          {url: '/user/operacional/unidade-medida', nome: "Unidade de Medida", icon: 'scale'},
          {url: '/user/operacional/residuo-tipo', nome: "Tipo de Resíduo", icon: 'delete_sweep'},
        ]
      },
      {
        url: '/user/dashboard2', nome: "Indicadores & Dashboard", icon: 'bar_chart', subMenu: [
          {url: '/user/dashboard2', nome: "Dashboard", icon: 'bar_chart'},
          {url: '/user/operacional/indicadores', nome: "Indicadores Regionais", icon: 'drag_indicator'},
          // {url: '/user/cadastro-indicadores-ods', nome: "Cadastrar Indicadores ODS", icon: 'drag_indicator'},
          // {url: '/user/indicadores-ods', nome: "Indicadores ODS", icon: 'drag_indicator'},
        ]
      },
      {
        url: '/user/financeiro', icon: 'payments', nome: 'Financeiro', subMenu: [
          {url: '/user/financeiro/cobrancas', nome: "Cobranças", icon: 'paid'},
          {url: '/user/financeiro/fluxo-cobranca', nome: "Fluxo Cobranças", icon: 'payments'},
          {url: '/user/financeiro/nota_fiscal', nome: "Nota Fiscal", icon: 'receipt'},
        ]
      },
      {
        url: '/user/contratarColetas', nome: "Contratar Coletas", icon: 'shopping_cart', subMenu: [
          {url: '/user/contratarColetas/cadastre-plano', nome: "Cadastre Plano", icon: 'edit_square'},
          {url: 'http://easyquote.com.br/login', nome: 'Cotação', icon: 'mail'},
          // {url: '/user/selecionar-plano', nome: 'Market Place', icon: 'local_mall'},
        ]
      },
      {
        url: '/user/comunicacao', icon: 'mail', nome: 'Comunicação', subMenu: [
          {url: '/user/comunicacao/newsletters', nome: "Newsletter", icon: 'description'},
          {url: '/user/comunicacao/grupo-comunicacao', nome: "Grupo Comunicação", icon: 'group'},
          {url: '/user/comunicacao/administracao', nome: "Administração", icon: 'settings_suggest'},
          {url: 'http://comunicacao.minhacoleta.com.br/', nome: 'Comunicação em Massa', icon: 'mail',},
          {url: '/user/white-label', nome: 'White Label', icon: 'picture_in_picture'},
        ]
      },
      {
        url: '/user/educacao-ambiental', nome: 'Educação ambiental', icon: 'recycling', subMenu: [
          {
            url: '/user/educacao-ambiental/gestao-jornada-educacao-ambiental',
            nome: 'Gestão Jornada Educação Ambiental',
            icon: 'emoji_objects'
          },
          {url: '/user/educacao-ambiental/cupom', nome: 'Crie Cupons de Desconto', icon: 'diamond'},
          {
            url: '/user/educacao-ambiental/cupomRelatorio',
            nome: "Relatórios de Jornada da Educação Ambiental",
            icon: 'assignment'
          },
        ]
      },
    ]
  }
]
