import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: 'input[type="number"]'
})
export class InputNumberDirective {

  constructor(private elRef: ElementRef) {
  }

  @HostListener('keydown', ['$event'])
  onKeyUp(e: KeyboardEvent) {
    const keyCode = e.keyCode;

    let numero = (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105);
    let controlos = [8, 37, 39].includes(keyCode);
    if (!numero && !controlos) return e.preventDefault();
  }

}
