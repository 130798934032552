<old-dg-modal
  #modal
  title="Editar Controle de Pesagem de Entrada"
  [afterClose]="close"
  size="md"
>
  <div dg-content>
    <form [formGroup]="form">
      <div class="row">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">DATA DA COLETA</label>
          <input
            class="form-control"
            type="date"
            disabled
            [value]="dataColeta"
          >
        </div>
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <label class="form-label">MOTORISTA/CATADOR</label>
          <input
            class="form-control"
            disabled
            [value]="motorista"
          >
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <label class="form-label">
            PESO DO CAMINHÃO NA SAÍDA (KG)
            <span class="fc-red">*</span>
          </label>
          <input
            class="form-control"
            formControlName="pesoCaminhaoSaida"
          >
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12">
          <label class="form-label">
            PESO DO CAMINHÃO NA CHEGADA (KG)
            <span class="fc-red">*</span>
          </label>
          <input
            class="form-control"
            formControlName="pesoCaminhaoChegada"
          >
        </div>
      </div>
      <div class="row mt-4">
                <span style="text-align: center">
                    PESO TOTAL DE RESÍDUO (KG):
                    <b>{{format(getPesoTotal())}}</b>
                </span>
      </div>
    </form>
  </div>
  <div dg-footer>
    <button
      (click)="save()"
      class="btn btn-success m-1"
      dg-desable-by-period
    >
      Salvar
    </button>
    <button
      (click)="modal.close()"
      class="btn btn-secondary m-1"
    >
      Sair
    </button>
  </div>
</old-dg-modal>
