import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { CrudDataTableComponent, InterfaceCustomActions } from '@datagrupo/dg-crud';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import Swal from 'sweetalert2';
import { IndustriaDestinoEntity } from '../industria-destino.entity';
import { IndustriaDestinoService } from '../service/industria-destino.service';

@Component({
  selector: 'app-industria-destino-main',
  templateUrl: './industria-destino-main.component.html',
  styleUrls: ['./industria-destino-main.component.scss']
})
export class IndustriaDestinoMainComponent implements OnInit {

  public mapEntity = new IndustriaDestinoEntity()
  @ViewChild('tableIndustriaDestino') tableIndustriaDestino!: CrudDataTableComponent;
  public customAction: InterfaceCustomActions[] = [
    {
      name: 'Editar',
      action: (val: IndustriaDestinoEntity) => {
        this.route.navigate(['user/industriaDestino/' + val.id])
      }, 
      permission: this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? true : false
    },
    {
      name: 'Excluir',
      action: (val: IndustriaDestinoEntity) => {
        Swal.fire({
          icon: 'warning',
          title: "Excluir registro",
          text: 'Deseja excluir este registro? Esta ação é permanente.',
          showCancelButton: true,
          confirmButtonText: 'Excluir',
          confirmButtonColor: 'red',
          cancelButtonColor: 'blue',
          cancelButtonText: 'Cancelar'
        }).then(confirm => {
          if (confirm.isConfirmed){
            this.service.delet('industria/' + val.id).subscribe(() => this.tableIndustriaDestino._pesquisar())
            Swal.fire({
              title: 'Deletado com sucesso',
              text: 'Esse registro foi deletado com sucesso',
              icon: 'success',
              timer: 2000,
              showConfirmButton: false
            })
          }
        })
      }, 
      permission: this.session.checkPerfil(['ADMINISTRADOR', 'ADMINISTRATIVO']) ? true : false
    }
  ]


  constructor(
    public service: IndustriaDestinoService,
    public session: SessionService,
    public route: Router
  ) { }

  ngOnInit(): void {
  }

}
