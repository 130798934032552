import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CheckPerfilDirective} from "./check-perfil/check-perfil.directive";
import { InputNumberDirective } from './input-number/input-number.directive';
import { DgDesableByPeriodDirective } from './dg-desable-by-period/dg-desable-by-period.directive';
import { ForkCrudSalvarDirective } from './fork-crud-salvar/fork-crud-salvar.directive';



@NgModule({
  declarations: [
    CheckPerfilDirective,
    InputNumberDirective,
    DgDesableByPeriodDirective,
    ForkCrudSalvarDirective,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CheckPerfilDirective,
    InputNumberDirective,
    DgDesableByPeriodDirective,
    ForkCrudSalvarDirective
  ]
})
export class DirectivesModule { }
