<div class="container-data">
  <div class="row">
    <div class="col-12">
      <ul *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])" class="nav nav-tabs">
        <li class="nav-item" (click)="tableVisible = 'pesagem-entrada'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'pesagem-entrada'">Controle de Pesagem de Entrada</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'dados-coleta'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'dados-coleta'">Dados da coleta</h4>
        </li>
      </ul>
    </div>
  </div>
</div>
<div
  id="galpao-main"
  class="container"
>
  <div class="row">
    <div class="col-12">
      <card>

        <div [hidden]="tableVisible != 'pesagem-entrada'">
          <div class="container">
            <div class="row">
              <div class="col-12 d-inline-flex justify-content-end">
                <h6 class="ms-auto">
                  Peso total: {{format(pesoTotalColetas)}} (KG)
                </h6>
              </div>
            </div>
          </div>

<!--          <div class="filter-search" style="max-width: 100% !important; position: relative">-->
<!--            <div class="container-fluid">-->
<!--              <div class="col-12 p-1">-->
<!--                <p class="destaque-claro f-bold">Pesquisar</p>-->
<!--                <hr>-->
<!--              </div>-->
<!--            </div>-->
<!--            <div class="container-fluid">-->
<!--              <div class="row">-->
<!--                <div class="col-3">-->
<!--                  <label class="form-label">Data inicial</label>-->
<!--                  <input-->
<!--                    class="form-control" [(ngModel)]="filters.dataInicial" type="date"-->
<!--                    dg-dynamic-group="controle-pesagem" paramName="dataInicial"-->
<!--                  >-->
<!--                </div>-->
<!--                <div class="col-3">-->
<!--                  <label class="form-label">Data final</label>-->
<!--                  <input-->
<!--                    class="form-control" [(ngModel)]="filters.dataFinal" type="date"-->
<!--                    dg-dynamic-group="controle-pesagem" paramName="dataFinal"-->
<!--                  >-->
<!--                </div>-->
<!--              </div>-->
<!--            </div>-->

<!--            <div class="container-fluid">-->
<!--              <div class="col-12 p-1">-->
<!--                <hr>-->
<!--              </div>-->
<!--            </div>-->

<!--          </div>-->
          <div class="card-subtitle">
            <filter-search2 dynamicGroupName="controle-pesagem">
              <div class="row">
                <div class="col-xl-3 clol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">Data inicial</label>
                  <input
                    class="form-control" [(ngModel)]="filters.dataInicial" type="date"
                    dg-dynamic-group="controle-pesagem" paramName="dataInicial"
                  >
                </div>
                <div class="col-xl-3 clol-lg-3 col-md-6 col-sm-12">
                  <label class="form-label">Data final</label>
                  <input
                    class="form-control" [(ngModel)]="filters.dataFinal" type="date"
                    dg-dynamic-group="controle-pesagem" paramName="dataFinal"
                  >
                </div>
              </div>
            </filter-search2>
          </div>

          <div class="card-body">
            <dg-table classes="crud-table" dg-dynamic-table [dynamicTable]="table"></dg-table>
          </div>
          <div class="card-footer d-inline-flex justify-content-center">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="table"></dg-paginator>
          </div>
        </div>

        <div [hidden]="tableVisible != 'dados-coleta'">
          <div class="card-body">
            <dg-table classes="crud-table" dg-dynamic-table [dynamicTable]="tableDadosColeta"></dg-table>
          </div>
          <div class="card-footer d-inline-flex justify-content-center">
            <dg-paginator dg-dynamic-paginator [dynamicTable]="tableDadosColeta"></dg-paginator>
          </div>
        </div>


      </card>
    </div>
  </div>
</div>

<modal-controle-pesagem #modal></modal-controle-pesagem>



<page-footer>
  <button
    class="btn btn-secondary"
    (click)="downloadExcel()"
  >
    Download excel
  </button>
</page-footer>
