import {AbstractEntity2} from "@datagrupo/dg-crud";
import {CdkDgTable, DynamicColumn, DynamicTableEntity} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../environments/environment";
import Swal from "sweetalert2";
import {HttpService} from "../../../../services/http-service/http.service";
import {SessionService} from "../../../../core/services/session-service/session.service";

export function helpersTableMTR(http: HttpService, session: SessionService) {
  return {
    tdResponse: (row: MtrEntity) => {
      let result = '--';

      if (!!row.descricaoErro) {
        if (row.descricaoErro.trim() == 'JSON format error: respostaApiwsManifestoDTO is not an array or is empty'.trim()) {
          return 'Verifique seu pedido de MTR, pois tem informações não identificadas que não foram preenchidas';
        }
        return row.descricaoErro;

      } else if (!!row?.responseBody) {
        const responseBody = JSON.parse(row.responseBody);

        if (responseBody?.respostaApiwsManifestoDTO) {
          return responseBody.respostaApiwsManifestoDTO
            ?.filter((item: any) => !!item.restResponseMensagem)
            ?.map((item: any) => item.restResponseMensagem)
            ?.join(', ');
        }
      }

      return result
    },
    motivoErro: {
      name: 'Verifica requisitos',
      action: (row:MtrEntity) => {
        http.get('/mtr/validate/coleta/'+row.coletaId).subscribe(
          (resp: any) => {
            Swal.fire({
              icon: 'question',
              title: 'Dados do MTR',
              text: resp.data
            }).then()
          }
        )
      },
      permission: (row:MtrEntity) => {
        return !row.mtrCodigo;
      }
    },
    cancelar: {
      name: 'Cancelar',
      action: (row:MtrEntity) => {
        Swal.fire({
          title: "Motivo do cancelamento",
          input: "text",
          inputAttributes: {
            autocapitalize: "off"
          },
          showCancelButton: true,
          cancelButtonText: 'Sair',
          confirmButtonText: "Cancelar MTR",
          showLoaderOnConfirm: true,
          preConfirm: async (justificativa) => {
            try {
              if (!justificativa) {
                throw 'Informa uma justificativa'
              }
            } catch (error) {
              Swal.showValidationMessage(`
                    Request failed: ${error}
                  `);
            }
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            http.http.post(
              environment.apiMTR + 'mtr-manager/mtr/cancelamento/',
              {
                "coletaId": row.coletaId,
                "justificativa": result.value,
                "manNumero": row.mtrCodigo
              }
            ).subscribe((resp: any) => {
              Swal.fire({
                icon: 'success',
                title: 'MTR cancelado'
              }).then()
              console.log(resp)
            })
          }
        }).then();
      },
      permission: (row:MtrEntity) => !session.checkPerfil('PARCEIRO')
    },
    anexar: {
      name: 'Anexar MTR a coleta',
      action: (row: MtrEntity) => {
        http.http.post(
          environment.apiMTR + 'mtr-manager/mtr/'+row.mtrCodigo+'/coleta/'+row.coletaId+'/download-pdf'
        ).subscribe((resp: any) => {
          Swal.fire({
            icon: 'success',
            title: "Pedido de emissão do MTR em andamento"
          }).then();
        })
      },
      permission: (row:MtrEntity) => !!row?.mtrCodigo && !session.checkPerfil('PARCEIRO')
    },
    reemitir: {
      name: 'Reemitir MTR',
      action: (row:MtrEntity) => {
        http.http.post(environment.apiMTR + 'mtr-manager/mtr/coleta/' + row.coletaId).subscribe((resp: any) => {
          Swal.fire({
            icon: 'success',
            title: "Pedido de emissão do MTR em andamento"
          }).then();
        })
      },
      permission: (row:MtrEntity) => row?.status == 'ERRO' && !session.checkPerfil('PARCEIRO')
    },
    verColeta: {
      name: 'Ir para coleta',
      action: (row:MtrEntity) => {
        window.open('/user/operacional/coletas/coletas/'+row.coletaId)
      },
      externalLink: true,
    }
  }
}

@DynamicTableEntity({
  api: {
    path: environment.apiMTR,
    context: 'mtr-manager/mtr/history'
  },
})
export class MtrEntity extends AbstractEntity2 {
  constructor(
    mtrCodigo?: string,
    coletaId?: string,
    clienteId?: string,
    responseBody?: any,
    status?: string,
    descricaoErro?: string,
  ) {
    super();
    this.mtrCodigo = mtrCodigo
    this.coletaId = coletaId
    this.clienteId = clienteId
    this.responseBody = responseBody
    this.status = status
    this.descricaoErro = descricaoErro
  }

  @DynamicColumn({headerName: 'Código MTR'})
  mtrCodigo: string | undefined;

  @DynamicColumn({headerName: 'Id da coleta'})
  coletaId: string | undefined;

  @DynamicColumn({headerName: 'Cliente'})
  clienteId: string | undefined;

  @DynamicColumn({headerName: 'Retorno'})
  responseBody: any | undefined;
  status: string | undefined;

  @DynamicColumn({headerName: 'Status'})
  descricaoErro: string | undefined;
}
