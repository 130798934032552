<old-dg-modal
  #modal
  size="md"
  title="Tipo de Resíduo"
  [afterClose]="afterClose"
>
  <div dg-content>
    <label class="form-label">Tipo de Resíduo</label>
    <select class="form-control" [formControl]="tipoLixo">
      <option value="" disabled>Selecione um tipo de resíduo</option>
      <option *ngFor="let tipo of listTipoLixo" [value]="tipo.id">
        NOME: {{tipo.nome || '--'}} |
        CATEGORIA: <span style="color: red !important;" *ngIf="!!tipo.categoria">{{tipo.categoria}}</span>
      </option>
    </select>
  </div>
  <div dg-footer>
    <button (click)="salvar()" class="btn btn-success m-1" dg-desable-by-period>Salvar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
