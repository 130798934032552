import {FormGroup} from "@angular/forms";


export namespace InputHelpers {

  /******************** DINHEIRO ********************/
  //Use esse metodos para gerir entradas de informações de dinheiro
  /**
   * Força o input iniciar os dados pelos decimais
   * @param form
   * @param values
   * @param campos
   */
  export function ajusteInputMoney(
    form: FormGroup,
    values: { [key: string]: number | string },
    campos: string | string[]
  ) {
    if (typeof campos == "string") campos = [campos];

    campos.map(campo => {
      if (campo in values) {
        form.patchValue(
          {
            [campo]: _ajusteInputMoney(values[campo])
          },
          {emitEvent: false}
        )
      }
    })
  }

  /**
   * Metodo de inicialização dos inputs de dinheiro, necessário para evitar problemas com
   * valores antigos guardados no banco.
   * @param val
   */
  export function initInputMoney(val: string | number): string {
    return Number(val || 0)
      .toLocaleString("BRL", {maximumFractionDigits: 2, minimumFractionDigits: 2});
  }

  /**
   * metodo que realmente realiza a mascara
   * @param value
   * @private
   */
  function _ajusteInputMoney(value: string | number) {
    let val = String(value).replace(/\D/g, '')
    val = String(val).replace(/\./g, '')
    val = String(val).replace(',', '')
    if (!String(val).trim() || isNaN(Number(val))) return '0,00';

    if (val.length < 3) {
      if (val.length == 1) val = '00' + val
      if (val.length == 2) val = '0' + val
    }

    const int = Number(val.slice(0, val.length - 2))
    const decimal = val.slice(val.length - 2)

    return Number(`${int}.${decimal}`)
      .toLocaleString("BRL", {maximumFractionDigits: 2, minimumFractionDigits: 2});
  }
  /**
   * metodo que realiza a reversão dos valores de REAL para DOLAR para ser guardado no banco de dados
   * @param value
   */
  export function ajusteMoneyBrToEua(value: string | number) {
    let val = String(value).replace(/\./g, '')
    return String(val).replace(',', '.')
  }

  /******************** DECIMAIS EM 3 CASAS ********************/

  /**
   * Força o input iniciar os dados pelos decimais
   * @param form
   * @param values
   * @param campos
   */
  export function ajusteInputDecimal(
    form: FormGroup,
    values: { [key: string]: number | string },
    campos: string | string[]
  ) {
    if (typeof campos == "string") campos = [campos];

    campos.map(campo => {
      if (campo in values) {
        form.patchValue(
          {
            [campo]: _ajusteInputDecimal(values[campo])
          },
          {emitEvent: false}
        )
      }
    })
  }

  /**
   * metodo que realmente realiza a mascara
   * @param value
   * @private
   */
  export function _ajusteInputDecimal(value: string | number) {
    let val = String(value).replace(/\D/g, '')
    val = String(val).replace(/\./g, '')
    val = String(val).replace(',', '')
    if (!String(val).trim() || isNaN(Number(val))) return '0,000';

    if (val.length < 4) {
      if (val.length == 1) val = '000' + val
      if (val.length == 2) val = '00' + val
      if (val.length == 3) val = '0' + val
    }

    const int = Number(val.slice(0, val.length - 3))
    const decimal = val.slice(val.length - 3)

    return Number(`${int}.${decimal}`)
      .toLocaleString("BRL", {maximumFractionDigits: 3, minimumFractionDigits: 3});
  }

  /**
   * Metodo de inicialização dos inputs de dinheiro, necessário para evitar problemas com
   * valores antigos guardados no banco.
   * @param val
   */
  export function initInputDecimal(val: string | number): string {
    return Number(val || 0)
      .toLocaleString("BRL", {maximumFractionDigits: 3, minimumFractionDigits: 3});
  }
}
