<old-dg-modal
  #modal
  id="modal-coleta-pontual"
  size="xl" title="Coleta Pontual"
  [afterClose]="clear"
>
  <div dg-content style="overflow: visible;">
    <form [formGroup]="form">
      <div class="row">
        <div class="col-4">
          <label class="form-label">Informe a data</label>
          <input class="form-control" type="date" formControlName="data">
        </div>
        <div class="col-4">
          <label class="form-label">Agrupar MTR</label>
          <select class="form-select" formControlName="grouped">
            <option [value]="true">agrupado</option>
            <option [value]="false">individual</option>
          </select>
        </div>
      </div>
      <div class="row" *ngIf="listContainers.length > 0">
        <div class="col-xs-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">Responsável</label>
          <input class="form-control" formControlName="responsavelMtr">
        </div>
        <div class="col-xs-3 col-xl-3 col-lg-4 col-md-6 col-sm-12">
          <label class="form-label">Cargo</label>
          <input class="form-control" formControlName="cargoMtr">
        </div>
      </div>
    </form>
    <div class="row">
      <div class="col-12">
        <hr>
      </div>
      <div>
        <label class="form-label">Selecione um recipiente</label>
        <table class="table">
          <thead>
          <tr>
            <th>Identificação</th>
            <th>Transportador</th>
            <th>Tipo do Resíduo</th>
            <th>Recipiente</th>
            <th>Tratamento</th>
            <th>MTR</th>
            <th
              data-bs-toggle="tooltip" data-bs-placement="top"
              title="Apenas os valorez iguais a '0' ou vazios serão alterados"
            >
              Peso MTR
              <input type="number" min="0" [(ngModel)]="pesoMtr" (change)="setPesoMtr()" class="form-control" placeholder="adicionar peso">
            </th>
            <th>Unidade de Medida Padrão</th>
            <th>Obs</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let container of listContainers; let i = index">
            <tr>
              <td>
                <label>
                  <input type="checkbox" [value]="(container.identificacao)"
                         (click)="select(container.id)" [checked]="verify(container.id)">
                  {{container.identificacao}}
                </label>
              </td>
              <td>
                {{container.galpao?.nome}}
              </td>
              <td>
                {{container.tipoLixoContainer?.nome}}
              </td>
              <td>
                {{container.container?.nome}}
              </td>
              <td>
                {{container.tratamento?.nome}}
              </td>
              <td>
                <input
                  #input
                  type="checkbox"
                  (click)="select(container.id, 'mtr')"
                  [checked]="verify(container.id)" [disabled]="!verify(container.id)"
                >
              </td>
              <td>
                <input type="number" required min="0" [(ngModel)]="container.pesoMtr" class="form-control">
              </td>
              <td>
                {{container.unidadeMedidaPadrao?.nome}}
              </td>
              <td>
                <button class="btn btn-success btn-sm" (click)="container['openObs']= !container?.openObs">+</button>
              </td>
            </tr>
            <tr *ngIf="!!container?.openObs">
              <td colspan="100%">
                <label class="form-label">Observações</label>
                <textarea [(ngModel)]="container['observacoesMtr']" class="form-control"></textarea>
              </td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div dg-footer>
    <button (click)="enviarPontual()" class="btn btn-success m-1">Enviar</button>
    <button (click)="modal.close()" class="btn btn-secondary m-1">Sair</button>
  </div>
</old-dg-modal>
