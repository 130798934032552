<div id="galpao-main" class="container">
  <div class="row">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Geradores</h4>
        </div>
        <div class="card-subtitle">
          <filter-search [entityMap]="mapEntity" [table]="tableCliente" tableEventId="clienteTable"></filter-search>
        </div>
        <div class="card-body" style="padding: 0">
          <crud-data-table
            #tableCliente
            [entityMap]="mapEntity"
            [showPagination]="false"
            tableEventId="clienteTable"
            [register]="true"
            [customActions]="session.checkPerfil('PARCEIRO') ? tableClientes: undefined "
            ></crud-data-table>
          <div class="d-flex justify-content-center card-footer">
            <dg-pagination
              [dataPagination]="mapEntity.configEntityTable.pagination"
              (changePage)="tableCliente.changePages($event)"
            ></dg-pagination>
          </div>
        </div>
      </card>
    </div>
  </div>
</div>


<page-footer>
<!--  <button crud-find-filters class="btn btn-success">Pesquisar</button>-->
<!--  <button crud-table-clear class="btn btn-secondary">Limpar</button>-->
  <a routerLink="novo">
    <button class="btn btn-success">Novo</button>
  </a>
   <button (click)="baixarPlanilha()" class="btn btn-secondary">Baixar planilha</button>
</page-footer>
