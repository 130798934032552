<div id="coletas-main" class="container">
  <div class="row">
    <div class="col-12">
      <ul class="nav nav-tabs">
        <li class="nav-item" (click)="tableVisible = 'coletas'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'coletas'">Coletas</h4>
        </li>
        <li
          *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
          class="nav-item"
          (click)="tableVisible = 'coletas-pendentes'"
        >
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'coletas-pendentes'">Coletas Pendentes</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'coletas-canceladas'">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'coletas-canceladas'">Coletas Canceladas</h4>
        </li>
        <li class="nav-item" (click)="tableVisible = 'mtr'" *ngIf="!session.checkPerfil('GALPAO')">
          <h4 class="nav-link card-title" [class.active]="tableVisible == 'mtr'">MTR</h4>
        </li>
      </ul>
    </div>
  </div>

  <div class="row mt-3" [hidden]="tableVisible != 'coletas'">
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title" [class.active]="tableVisible == 'coletas'">Coletas</h4>
        </div>
        <div class="card-subtitle">
          <filter-search [entityMap]="mapEntity" [table]="crudTable" tableEventId="ColetasEntity"></filter-search>
        </div>
        <div class="card-body" style="padding: 0">
          <crud-data-table
            #crudTable
            tableEventId="ColetasEntity"
            [entityMap]="mapEntity"
            [service]="service"
            [showDefaultAction]="'editar'"
            [showPagination]="false"
            [customActions]="customAction"
            [register]="true"
          ></crud-data-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-pagination
            [table]="crudTable"
            [entity]="mapEntity"
            [dataPagination]="mapEntity.configEntityTable.pagination"
            (changePage)="crudTable.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>

  <div
    *ngIf="session.checkPerfil(['GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR'])"
    class="row mt-3"
    [hidden]="tableVisible != 'coletas-pendentes'"
  >
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Coletas Pendentes</h4>
        </div>

        <div class="card-subtitle">
          <filter-search [entityMap]="mapEntityPendentes" [table]="crudTablePentendes" tableEventId="coletasPendentes"></filter-search>
        </div>
        <div class="card-body" style="padding: 0">
          <crud-data-table
            #crudTablePentendes
            tableEventId="coletasPendentes"
            [entityMap]="mapEntityPendentes"
            [register]="true"
          ></crud-data-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-pagination
            [dataPagination]="mapEntityPendentes.configEntityTable.pagination"
            (changePage)="crudTablePentendes.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>

  <div
    class="row mt-3"
    [hidden]="tableVisible != 'coletas-canceladas'"
  >
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">Coletas Canceladas</h4>
        </div>

        <div class="card-subtitle">
          <filter-search [entityMap]="mapEntityCanceladas" [table]="crudTableCanceladas" tableEventId="coletasCanceladas"></filter-search>
        </div>
        <div class="card-body" style="padding: 0">
          <crud-data-table
            #crudTableCanceladas
            tableEventId="coletasCanceladas"
            [entityMap]="mapEntityCanceladas"
          ></crud-data-table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-pagination
            [table]="crudTableCanceladas"
            [entity]="mapEntityCanceladas"
            [dataPagination]="mapEntityCanceladas.configEntityTable.pagination"
            (changePage)="crudTableCanceladas.changePages($event)"
          ></dg-pagination>
        </div>
      </card>
    </div>
  </div>

  <div
    class="row mt-3"
    [hidden]="tableVisible != 'mtr'"
  >
    <div class="col-12">
      <card>
        <div class="card-header">
          <h4 class="card-title">MTR</h4>
        </div>
        <div class="card-subtitle">
        </div>
        <div class="card-body card-mtr" style="padding: 0">
          <table class="mt-3 crud-table">
            <thead>
              <tr>
                <th>Código MTR</th>
                <th>Id da coleta</th>
                <th>Cliente</th>
                <th>Retorno</th>
                <th>Status</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of tableMTR.controls.data.dataSource">
                <td>{{item.mtrCodigo}}</td>
                <td>{{item.coletaId}}</td>
                <td>{{item.clienteId}}</td>
                <td>
                  <span
                    [style.color]="item?.status == 'ERRO' ? 'red' : 'inhiet'"
                  >
                    {{helpersTableMTR.tdResponse(item)}}
                  </span>
                </td>
                <td>{{item.status}}</td>
                <td class="table-colum-fixed-end mat-column-actions">
                  <div class="dropdown">
                    <button class="btn btn-success button-menu" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                      <mat-icon>more_vert</mat-icon>
                    </button>
                    <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                      <li
                        *ngIf="helpersTableMTR.motivoErro.permission(item)"
                        (click)="helpersTableMTR.motivoErro.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.motivoErro.name}}</span>
                      </li>
                      <li
                        (click)="helpersTableMTR.cancelar.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.cancelar.name}}</span>
                      </li>
                      <li
                        *ngIf="helpersTableMTR.reemitir.permission(item)"
                        (click)="helpersTableMTR.reemitir.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.reemitir.name}}</span>
                      </li>
                      <li
                        *ngIf="helpersTableMTR.anexar.permission(item)"
                        (click)="helpersTableMTR.anexar.action(item)"
                      >
                        <span class="dropdown-item">{{helpersTableMTR.anexar.name}}</span>
                      </li>
                      <li>
                        <a href="/user/operacional/coletas/coletas/{{item.coletaId}}" target="_blank" class="dropdown-item">{{helpersTableMTR.verColeta.name}}</a>
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr *ngIf="tableMTR.controls.data.dataSource.length == 0">
                <td colspan="100%">
                  Nenhum registro encontrado
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="d-flex justify-content-center card-footer">
          <dg-paginator dg-dynamic-paginator [dynamicTable]="tableMTR"></dg-paginator>
        </div>
      </card>
    </div>
  </div>
</div>


<page-footer *ngIf="!!session.user.cliente_id">
  <button class="btn btn-success" (click)="modalPlanejamento.open()">Plano de
    Coleta</button>
  <button class="btn btn-success" (click)="modalColetaPontual.open()">Coleta
    Pontual</button>
</page-footer>

<modal-coleta-pontual #modalColetaPontual></modal-coleta-pontual>

<modal-plano-coleta #modalPlanejamento></modal-plano-coleta>
