<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])"
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <card>
      <div class="card-header w-100">
        <div class="container-fluid">
          <div class="row">
            <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <h4 class="card-title">Relação contrato</h4>
            </div>
            <card-header-buttons class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
              <button
                *ngIf="verify.permissionAddContainer()"
                (click)="modalRelacaoContrato.open()"
                class="btn btn-success btn-card-header"
              >
                <mat-icon>add</mat-icon>
                Cadastrar
              </button>
            </card-header-buttons>
          </div>
        </div>
      </div>

      <div class="card-subtitle">
        <div class="row">
          <filter-search2 dynamicGroupName="cliente-relacao-contrato">
            <div class="row">
              <div class="col-3">
                <label class="form-label">Status</label>
                <select
                  class="form-select"
                  [ngModel]="filterInputs.relacaoContrato.isAtivo"
                  dg-dynamic-group="cliente-relacao-contrato"
                  paramName="isAtivo"
                >
                  <option [value]="true">Ativos</option>
                  <option [value]="false">Inativos</option>
                  <option value="">Ambos</option>
                </select>
              </div>
            </div>
          </filter-search2>
        </div>
      </div>

      <div class="card-body">
        <dg-table dg-dynamic-table [dynamicTable]="tableRelacaoContrato" classes="default-table"></dg-table>
      </div>
      <div class="card-footer">
        <dg-paginator dg-dynamic-paginator [dynamicTable]="tableRelacaoContrato"></dg-paginator>
      </div>
    </card>
  </div>
</div>

<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'GALPAO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])"
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <planos-clientes-table [id]="clienteId"></planos-clientes-table>
  </div>
</div>

<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO'])"
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <planos-pontual-clientes-table [id]="clienteId"></planos-pontual-clientes-table>
  </div>
</div>

<div
  *ngIf="!!clienteId && session.checkPerfil(['CONDOMINIO', 'ADMINISTRATIVO', 'ADMINISTRADOR', 'PARCEIRO']) "
  class="row mt-3"
>
  <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
    <card>
      <div class="card-header">
        <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12">
          <h4 class="card-title">Missões aceitas pelo gerador</h4>
        </div>
      </div>
      <div class="card-body">
        <dg-table dg-dynamic-table [dynamicTable]="tableMissoesAceitas" classes="default-table"></dg-table>
      </div>
      <div class="card-footer">
        <dg-paginator dg-dynamic-paginator [dynamicTable]="tableMissoesAceitas"></dg-paginator>
      </div>
    </card>
  </div>
</div>


<modal-cliente-relacao-contrato
  #modalRelacaoContrato
  [idCliente]="clienteId"
  (afterSave)="tableRelacaoContrato.find()"
></modal-cliente-relacao-contrato>

