
export const DASHBOARD = 'dashboard';
export const DASHBOARD_TABLE = 'dashboard/datatable';
export const DASHBOARD_TABLE_GRI = 'dashboard/gri-datatable';

export const GALPAO = 'galpoes'
export const GALPAO_FIND_FILTER = 'galpoes/nomes'
export const GALPAO_DOCUMENTO = 'galpoes/documentogalpao'
export const GALPAO_DOCUMENTO_DOWNLOAD = 'galpoes/documentogalpao/download'
export const GALPAO_MOTORISTA = 'motoristas';
export const GALPAO_MOTORISTA_NOME = 'motoristas/nomes';
export const GALPAO_VEICULO = 'veiculos';
export const GALPAO_CONTAINER = 'galpao-containers';
export const GALPAO_COLETAS = 'coletas/galpao';

export const ARQUIVO_MOTORISTA = 'arquivo-motorista';
export const ARQUIVO_MOTORISTA_IMAGEM = 'arquivo-motorista/imagem';

export const COBRANCAS = 'cobrancas'

export const VEICULO = 'veiculos';

export const NEWSLETTER = 'newsletter';

export const HISTORICOS = 'historicos'

export const PLANOS = 'planos';
export const PLANOS_FIND_FILTER = 'planos/nomes';

export const FLUXO_COBRANCAS = 'fluxocobranca'

export const BLOG = 'blog'

export const COLETAS = 'coletas'
export const COLETA_PESO = 'coletas/pesocoleta/'
export const COLETA_ROTA = 'coletas/rota'
export const COLETA_AVALIACAO = 'coletas/avaliacao'
export const COLETA_PLANO_REGULAR = 'coletas/plano-regular'
export const COLETA_RANKING_TAXA = 'coletas/ranking-coleta/taxa-reciclagem'
export const COLETA_RANKING_ATERRO = 'coletas/ranking-coleta/aterro-zero'
export const COLETA_RANKING_RECICLAGEM = 'coletas/ranking-coleta/taxa-reciclagem'

export const PRODUTOS = 'produtos';

export const CLIENTE_CREDITO_LOGISTICA_REVERSA_PRODUTOS = 'cliente-credito-logistica-reversa/produtos'
export const CLIENTE_CREDITO_LOGISTICA_REVERSA_ARQUIVOS = 'cliente-credito-logistica-reversa/arquivos'
export const CLIENTE_CREDITO_LOGISTICA_REVERSA_ARQUIVOS_DOWNLOAD = 'cliente-credito-logistica-reversa/arquivos/download/'

export const MISSAO_CUPOM = 'missao/cupom/'

export const ROTAS = 'rotas';

export const ROTAS_BY_ID = 'rotas/find';

export const MISSOES = 'missoes'
export const NOTA_FISCAL = 'notafiscal'

export const GRUPO_COMUNICACAO = 'grupo-comunicacao'
export const GRUPO_COMUNICACAO_FIND_FILTER = 'grupo-comunicacao/nomes'

export const TIPO_LIXO = 'tipo-lixo'
export const TIPO_LIXO_FIND_FILTER = 'tipo-lixo/nomes'
export const TIPO_LIXO_GALPAO = 'tipo-lixo-galpao/';

export const TRATAMENTO = 'tratamento';

export const CHECKPOINTS = 'checkpoints'
export const CHECKPOINTS_CONTAINER  = 'checkpoints/checkin-containers/'

export const DASHBOARD_FILTER_UNIDADE_GERADORA = 'dashboard/filtro/unidade';
export const DASHBOARD_FILTER_OPERADOR_LOGISTICO = 'dashboard/filtro/operador';
export const DASHBOARD_FILTER_OPERADOR_TIPOLIXO = 'tipo-lixo/present-on-cliente-container';
export const DASHBOARD_ATERRO_ZERO = 'dashboard/aterro-zero';

export const PERGUNTAS = 'pergunta-respostas';

export const VERIFY_LOGIN_EXIST = 'auth/username';

export const WHITE_LABELS = 'white-labels';
export const WHITE_LABELS_LOGO = 'white-labels/logo';

export * from './clientes.endpoints';
