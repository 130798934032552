import {
  AbstractEntity,
  DataServer,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn
} from "@datagrupo/dg-crud";
import {environment} from "src/environments/environment";
import {Enderecos} from "../../../../shared/entitys/enderecos";
import {ParceiroEntity} from "../../parceiro/parceiro.entity";


const statusList = [
  {key: 'all', value: 'Ambos'},
  {key: 'true', value: 'Ativo'},
  {key: 'false', value: 'Inativo'}
]
const filterFunc = (val: string, nameParam: string) => {
  if (val == 'all') {
    return
  }

  return {
    [nameParam]: val
  }
}

@DataServer({
  path: environment.apiUrl,
  context: 'galpoes'
})
export class GalpaoEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    isAtivo?: boolean,
    ativo?: boolean,
    enderecos?: Enderecos[],
    nome?: string,
    cnpj?: string,
    email?: string,
    emailDoOperadorLogistico?: string,
    telefone?: string,
    parceiro?: ParceiroEntity,
  ) {
    super();
    this.id = id;
    this.isAtivo = isAtivo;
    this.nome = nome;
    this.cnpj = cnpj;
    this.email = email;
    this.emailDoOperadorLogistico = emailDoOperadorLogistico
    this.telefone = telefone;
    this.enderecos = Array.isArray(enderecos) ? enderecos : [];
    this.parceiro = parceiro;
    this.ativo = ativo;
  }

  @DgFilter_autoComplete({colum: '4'}, {
    getUrl: environment.apiUrl + 'galpoes/nomes',
    getServerData: "all",
    // propertKey: 'id',
    propertValue: 'nome',
  })
  @DgTableColumn({
    columnName: 'TRANSPORTADOR', sort: true, resource: (val: string, row: GalpaoEntity) => {
      if (row?.ativo == false) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val ? val : '--'}</span>`
      }
      return val
    }
  })
  public nome: string | undefined;

  @DgFilter_input('text', {colum: '4', mask: 'cnpj'})
  @DgTableColumn({columnName: 'CNPJ', mask: 'cnpj', sort: true})
  public cnpj: string | undefined

  @DgFilter_select({
    name: 'STATUS',
    data: statusList,
    propertKey: 'key',
    propertValue: 'value',
    nameParamFilter: 'ativo',
    outputFormat: (val: string) => filterFunc(val, 'isAtivo')
  })
  public isAtivo: boolean | undefined;
  @DgTableColumn({columnName: 'E-MAIL', sort: true})
  public email: string | undefined;

  public enderecos: Enderecos[] = [];
  public emailDoOperadorLogistico: string | undefined;
  public telefone: string | undefined;
  public linkDashboard: string | undefined;
  public parceiro: ParceiroEntity | undefined;
  public ativo: boolean | undefined;
}
