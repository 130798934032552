import { HttpClient } from '@angular/common/http';
import { ConfigDgCrudService } from 'src/app/core/config/config-dg-crud/config-dg-crud.service';
import { IndustriaDestinoService } from '../service/industria-destino.service';
import { Validators } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import { AbstractInsertEdit } from '@datagrupo/dg-crud';
import { IndustriaDestinoEntity } from '../industria-destino.entity';
import { HttpHelpersService } from 'src/app/services/http-helpers/http-helpers.service';
import Swal from 'sweetalert2';
import { defaultCallBackSuccessGetCep, defaultCallBackErrorGetCep } from 'src/app/helpers/helpers';
import { SessionService } from 'src/app/core/services/session-service/session.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-industria-destino-insert-edit',
  templateUrl: './industria-destino-insert-edit.component.html',
  styleUrls: ['./industria-destino-insert-edit.component.scss']
})
export class IndustriaDestinoInsertEditComponent extends AbstractInsertEdit<IndustriaDestinoEntity> implements OnInit {

  public data: IndustriaDestinoEntity[] = [];
  public industriaAssociada: boolean = false;

  public form = new FormGroup({
    nome: new FormControl({ value: '', disabled: false }, [Validators.required]),
    cnpj: new FormControl({ value: '', disabled: false }, [Validators.required]),
    inscricaoEstadual: new FormControl({ value: '', disabled: false }, [Validators.required]),
    cep: new FormControl({ value: '', disabled: false }, [Validators.required]),
    numero: new FormControl({ value: '', disabled: false }, [Validators.required]),
    complemento: new FormControl({ value: '', disabled: false }),
    logradouro: new FormControl({ value: '', disabled: true }, [Validators.required]),
    bairro: new FormControl({ value: '', disabled: true }, [Validators.required]),
    cidade: new FormControl({ value: '', disabled: true }, [Validators.required]),
    uf: new FormControl({ value: '', disabled: true }, [Validators.required]),
  })


  constructor(
    public servic: IndustriaDestinoService,
    public conf: ConfigDgCrudService,
    public http: HttpClient,
    private httpHelp: HttpHelpersService,
    public session: SessionService,
    public route: Router
  ) {
    super(conf, servic);

    this.crudConfig.subscribeEntityAfterSave = false;

    this.form.controls['inscricaoEstadual'].valueChanges.subscribe((val:string) => {
      this.form.patchValue({inscricaoEstadual: val.replace(/\D/g, '')}, {emitEvent: false})
    })
  }

  override ngOnInit(): void {
    super.ngOnInit()
    this.servic.findAll().subscribe(resp => {
      this.data = resp.data
    })
  }

  initNewEntity(): void {
    this.entity = new IndustriaDestinoEntity();
    this.getCep()
  }

  override afterFetchEntity() {
    this.form.patchValue({
      ...this.entity,
      ...this.entity.endereco,
      //@ts-ignore
      uf: this.entity.endereco.estado.uf
    })
    this.getCep();
  }

  getCep() {
    console.log(this.industriaAssociada)
    this.form.controls['cep'].valueChanges.subscribe(
      cep => {
        if (cep == this.form.value.cep) return

        if (this.industriaAssociada == false) {
          this.httpHelp.getCep(cep,
            data => {
              this.form.patchValue({
                "logradouro": data.logradouro,
                "bairro": data.bairro,
                "cidade": data.localidade,
                "uf": data.uf
              });
              defaultCallBackSuccessGetCep(this.form)
            },
            () => defaultCallBackErrorGetCep(this.form)
          )

        }
      }
    )
  }

  addCepManual() {
    defaultCallBackErrorGetCep(this.form, false)
  }

  getCNPJ() {
    let cnpj = this.form.value.cnpj
    if (cnpj) {
      if (cnpj.length == 14) {
        let cnpjIndex = this.data.findIndex(i => i.cnpj?.replace(/[^\d]+/g, '') == cnpj);
        if (cnpjIndex != -1) {
          Swal.fire({
            title: 'Aviso',
            text: 'Essa indústria/operador já existe, deseja continuar?',
            icon: 'question',
            showCancelButton: true,
            cancelButtonText: 'Não',
            confirmButtonText: 'Sim',
          }).then(confirm => {
            if (confirm.isConfirmed) {
              this.industriaAssociada = true
              this.associarIndustria(cnpjIndex)
            } else {
              this.form.reset('')
              return
            }
          })
        }
      }
    }
  }

  associarIndustria(index: number) {
    const info = this.data[index]
    this.form.patchValue({
      ...info,
      ...info.endereco,
      //@ts-ignore
      uf: info.endereco.estado.uf
    })
    if (this.session.checkPerfil(['GALPAO'])) {
      this.form.controls['nome'].disable()
      this.form.controls['cnpj'].disable()
      this.form.controls['inscricaoEstadual'].disable()
      this.form.controls['cep'].disable()
      this.form.controls['numero'].disable()
      this.form.controls['complemento'].disable()
    }

  }

  limparDados() {
    this.form.reset('')
    this.form.controls['nome'].enable()
    this.form.controls['cnpj'].enable()
    this.form.controls['inscricaoEstadual'].enable()
    this.form.controls['cep'].enable()
    this.form.controls['numero'].enable()
    this.form.controls['complemento'].enable()
    this.industriaAssociada = false
  }

  salvarAssociar() {
    if (!this.form.valid && this.industriaAssociada == false) {
      this.form.markAllAsTouched();
      return;
    }


    const { bairro, cep, cidade, numero, complemento, logradouro, uf, ...form } = this.form.getRawValue();
    let cnpjIndex = this.data.findIndex(i => i.cnpj?.replace(/[^\d]+/g, '') == form.cnpj);
    this.entity = {
      ...this.entity,
      id: !!this.industriaAssociada ? this.data[cnpjIndex].id : this.entity.id,
      ...form,
      endereco: {
        bairro,
        cep,
        cidade,
        numero,
        complemento,
        logradouro,
        estado: uf.toUpperCase()
      }
    }

    if (this.industriaAssociada == false && this.entity.id == undefined) {
      this.servic.post('industria', this.entity).subscribe(resp => {
        this.route.navigate(['user/industriaDestino'])
      })
    } else {
      this.servic.put('industria', this.entity).subscribe(resp => {
        this.route.navigate(['user/industriaDestino'])
      })
    }
  }

  checkData(industria: IndustriaDestinoEntity) {
    if (industria.id) {
      this.entity.nome = industria.nome
      this.servic.get('industria/' + industria.id).subscribe(resp => {
        console.log(resp)
        this.form.patchValue({
          cnpj: resp.data.cnpj,
          inscricaoEstadual: resp.data.inscricaoEstadual,
          cep: resp.data.endereco.cep,
          logradouro: resp.data.endereco.logradouro,
          numero: resp.data.endereco.numero,
          complemento: resp.data.endereco.complemento,
          bairro: resp.data.endereco.bairro,
          cidade: resp.data.endereco.cidade,
          uf: resp.data.uf
        })
      })
    }
  }

}
