import { IntegracaoMtrEntity } from './../clientes/integracao-mtr.entity';
import { AbstractEntity, DataServer, DgFilter_select, DgTableColumn } from "@datagrupo/dg-crud";
import { ContainerEntity } from "../../container/container.entity";
import { environment } from "../../../../../environments/environment";
import { CLIENTE_CONTAINER } from "../../../../core/config/endpoint-list";
import { GalpaoEntity } from "../../_galpao/galpao/galpao.entity";
import { GalpaoTipoLixoEntity } from "../../_galpao/galpao/galpao-tipo-lixo.entity";
import { UnidadeMedidaEntity } from "../../unidade-medida/unidade-medida.entity";
import { TratamentoEntity } from "../../tratamento/tratamento.entity";
import { IndustriaDestinoEntity } from "../../industria-destino/industria-destino.entity";


const statusList = [
  { key: 'all', value: 'Ambos' },
  { key: 'true', value: 'Ativo' },
  { key: 'false', value: 'Inativo' }
]
const filterFunc = (val: string, nameParam: string) => {
  if (val == 'all') {
    return
  }

  return {
    [nameParam]: val
  }
}
@DataServer({
  path: environment.apiUrl,
  context: CLIENTE_CONTAINER
})
export class ContainerClienteEntity extends AbstractEntity {
  constructor(
    id?: number | string,
    galpao?: GalpaoEntity,
    container?: ContainerEntity,
    identificacao?: string,
    clienteId?: string | number,
    densidade?: number | string,
    tipoLixoContainer?: GalpaoTipoLixoEntity,
    quantidade?: number | string,
    unidadeMedidaPadrao?: UnidadeMedidaEntity,
    listUnidadeMedida?: UnidadeMedidaEntity[],
    tratamento?: TratamentoEntity,
    dataDesativacao?: string,
    industria?: IndustriaDestinoEntity,
    dono?: 'CLIENTE' | 'GALPAO',
    diasColeta?: string[],
    integracaoMtr?: IntegracaoMtrEntity,
  ) {
    super();
    this.id = id
    this.container = container
    this.identificacao = identificacao
    this.tipoLixoContainer = tipoLixoContainer
    this.galpao = galpao
    this.clienteId = clienteId
    this.quantidade = quantidade;
    this.unidadeMedidaPadrao = unidadeMedidaPadrao;
    this.listUnidadeMedida = Array.isArray(listUnidadeMedida) ? listUnidadeMedida : [];
    this.tratamento = tratamento;
    this.dataDesativacao = dataDesativacao;
    this.industria = industria;
    this.dono = dono;
    this.densidade = densidade;
    this.integracaoMtr = integracaoMtr;
    this.diasColeta = Array.isArray(diasColeta) ? diasColeta : [];
  }

  @DgTableColumn({
    columnName: 'Identificador',
    resource: (val: any, row: ContainerClienteEntity) => {
      if (!!row.dataDesativacao) {
        return `<span style="text-decoration: line-through; opacity: .6">${val}</span>`
      }
      return val
    }, sort: true
  })
  public identificacao: string | undefined

  @DgFilter_select({
    name: 'STATUS',
    colum: '4',
    data: statusList,
    propertKey: 'key',
    propertValue: 'value',
    outputFormat: (val: string) => filterFunc(val, 'isAtivo')
  })
  public isAtivo: boolean | undefined;


  @DgTableColumn({
    columnName: 'Transportador',
    resource: (val: GalpaoEntity, row: ContainerClienteEntity) => {
      if (!!row.dataDesativacao) {
        return `<span style="text-decoration: line-through; opacity: .6">${val?.nome || '--'}</span>`
      }
      return val.nome
    }, sort: true
  })
  public galpao: GalpaoEntity | undefined;





  @DgTableColumn({
    columnName: 'Tipo do Resíduo',
    resource: (val: any, row: ContainerClienteEntity) => {
      if (!!row.dataDesativacao) {
        return `<span style="text-decoration: line-through; opacity: .6">${val?.nome || '--'}</span>`
      }
      return val?.nome || '--'
    }, sort: true
  })
  public tipoLixoContainer: GalpaoTipoLixoEntity | undefined;

  @DgTableColumn({
    columnName: 'Recipiente',
    resource: (val: ContainerEntity, row: ContainerClienteEntity) => {
      if (!!row.dataDesativacao) {
        return `<span style="text-decoration: line-through; opacity: .6">${!!val?.nome ? val.nome : '--'}</span>`
      }
      return !!val?.nome ? val.nome : '--'
    }, sort: true
  })
  public container: ContainerEntity | undefined;
  public clienteId: number | string | undefined;
  public densidade: number | string| undefined;
  public quantidade: number | string | undefined;
  public unidadeMedidaPadrao: UnidadeMedidaEntity | undefined
  public listUnidadeMedida: UnidadeMedidaEntity[] = [];
  public tratamento: TratamentoEntity | undefined;
  public dataDesativacao: string | undefined
  public industria: IndustriaDestinoEntity | undefined;
  public dono: 'CLIENTE' | 'GALPAO' | undefined
  public diasColeta: string[];
  public classe: string | undefined;
  public integracaoMtr: IntegracaoMtrEntity | undefined;

}
