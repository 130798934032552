import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'filter-search2',
  templateUrl: './filter-search2.component.html',
  styleUrls: ['./filter-search2.component.scss']
})
export class FilterSearch2Component implements OnInit {

  @Input('dynamicGroupName') dynamicGroupName: string = '';

  constructor() { }

  ngOnInit(): void {
  }

}
