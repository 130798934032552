import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IndustriaDestinoMainComponent } from './industria-destino-main/industria-destino-main.component';
import { IndustriaDestinoInsertEditComponent } from './industria-destino-insert-edit/industria-destino-insert-edit.component';
import { UiModule } from 'src/app/shared/ui/ui.module';
import { RouterModule } from '@angular/router';
import { NgxMaskModule } from 'ngx-mask';
import { MatIconModule } from '@angular/material/icon';
import { DgCrudModule } from '@datagrupo/dg-crud';
import { ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    IndustriaDestinoMainComponent,
    IndustriaDestinoInsertEditComponent
  ],
  imports: [
    CommonModule,
    UiModule,
    RouterModule,
    NgxMaskModule,
    MatIconModule,
    DgCrudModule,
    ReactiveFormsModule
  ]
})
export class IndustriaDestinoModule { }
