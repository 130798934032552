import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {PublicLayoutModule} from "./public/public-layout.module";
import {UserLayoutModule} from "./user/user-layout.module";
import {PrintLayoutModule} from "./print-layout/print-layout.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    PublicLayoutModule,
    UserLayoutModule,
    PrintLayoutModule,
  ]
})
export class LayoutsModule { }
