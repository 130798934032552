import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CdkDynamicTable, CdkDynamicTableService, DgAutocomplete3Component} from "@datagrupo/dg-ng-util";
import {environment} from "../../../../../../environments/environment";
import {
  COLETA_RANKING_ATERRO,
  COLETA_RANKING_RECICLAGEM,
} from "../../../../../core/config/endpoint-list";
import {FormControl, FormGroup} from "@angular/forms";
import {Subscription} from "rxjs";
import {ProgressLoader} from "../../../../../helpers/progress-loader";
import {DashboardFilterHelperService} from "../../services/dashboard-filter-helper/dashboard-filter-helper.service";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpService} from "../../../../../services/http-service/http.service";
import {FilesManageService} from "../../../../../services/files-manage/files-manage.service";
import Swal from "sweetalert2";
import {stringify} from "querystring";

@Component({
  selector: 'dashboard2-rankings',
  templateUrl: './dashboard2-rankings.component.html',
  styleUrls: ['./dashboard2-rankings.component.scss']
})
export class Dashboard2RankingsComponent implements OnInit, OnDestroy {

  @ViewChild('favoritos') favoritos!: DgAutocomplete3Component

  view: string = 'lixo-zero';

  public formFilter = new FormGroup({
    favoritos: new FormControl(''),
    geradores: new FormControl([]),
    dataInicio: new FormControl(''),
    dataFim: new FormControl(''),
    perfilUnidade: new FormControl('CONDOMINIO'),
    residuos: new FormControl([]),
    estados: new FormControl([]),
  })

  controlles: {
    // lidando com requests
    handleHequests: { [key: string]: Subscription | undefined },
    handleHequestsFilters: { [key: string]: Subscription | undefined },
    // lista de valores dos filtros
    filtersOptions: { [key: string]: any[] },
    // lista de graficos/requests que devem ser executados
    listLoaderPermission: string[],
    // lista de filtros que pode aparecer
    permissionFilters: string[],
    // controller de loader dos dados
    loaders: ProgressLoader.classe,
    // ultimos filtros buscados
    lastFilter: any
  } = {
    filtersOptions: {
      favoritos: [],
      estados: [],
      geradores: [],
      operadores: [],
      tipoResiduos: [],
      motoristas: [],
    },
    handleHequests: {
      info: undefined,
      anos: undefined,
      meses: undefined,
      totais: undefined,
      tipoResiduos: undefined,
      motoristas: undefined,
    },
    handleHequestsFilters: {},
    listLoaderPermission: [],
    permissionFilters: ['tipoResiduos', 'estados', 'geradores'],
    loaders: ProgressLoader.createLoader(),
    lastFilter: undefined
  }

  dataDashboard: {
    queryPrintLixoZero: { [key:string]: any },
    queryPrintTaxaReciclagem: { [key:string]: any },
  } = {
    queryPrintLixoZero: {},
    queryPrintTaxaReciclagem: {},
  }

  tableLixoZero: CdkDynamicTable.tableClass;
  tableReciclagem: CdkDynamicTable.tableClass;

  constructor(
    private http: HttpService,
    private createTable: CdkDynamicTableService,
    public filterHelpers: DashboardFilterHelperService,
    private router: Router,
    public activatedRoute: ActivatedRoute,
    private files: FilesManageService
  ) {
    this.tableLixoZero = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: COLETA_RANKING_ATERRO
      },
      pagination: {
        sort: ['clienteNome']
      },
      columns: [
        {
          name: 'posicao', headerName: 'POSIÇÃO', resource: (val: number | string) => {
            return '<span style="font-size: 13pt; font-weight: bolder; color: rgba(148,159,57,.8)">' + val + '</span>'
          }
        },
        {name: 'clienteNome', headerName: 'NOME'},
        {
          name: 'pontuacaoRanking', headerName: 'TAXA DE ATERRO ZERO (%)', resource: (val: number) => {
            return '<span style="padding: .3rem .7rem; background: rgba(148,159,57,.8); color: white; font-size: 14pt; border-radius: 25px">' +
              val.toFixed(1) +
              "% </span>"
          }
        },
      ],
      filters: {group: 'ranking-taxa', reactive: true}
    })
    this.tableReciclagem = createTable.create('request', {
      apiData: {
        path: environment.apiUrl,
        context: COLETA_RANKING_RECICLAGEM
      },
      pagination: {
        sort: ['clienteNome']
      },
      columns: [
        {
          name: 'posicao', headerName: 'POSIÇÃO', resource: (val: number | string) => {
            return '<span style="font-size: 13pt; font-weight: bolder; color: rgba(148,159,57,.8)">' + val + '</span>'
          }
        },
        {name: 'clienteNome', headerName: 'NOME'},
        {
          name: 'pontuacaoRanking', headerName: 'TAXA DE RECICLAGEM (%)', resource: (val: number) => {
            return '<span style="padding: .3rem .7rem; background: rgba(148,159,57,.8); color: white; font-size: 14pt; border-radius: 25px">' +
              val.toFixed(1) +
              "% </span>"
          }
        },
      ],
      filters: {group: 'ranking-taxa', reactive: true}
    })

    this.loaderFavoritos()

    if (activatedRoute.snapshot.queryParams['rankings']) {
      const filtersParams = JSON.parse(activatedRoute.snapshot.queryParams['rankings'])
      this.formFilter.patchValue(filtersParams);
      this.tableLixoZero.controls.filters.patchValue(filtersParams);
      this.tableReciclagem.controls.filters.patchValue(filtersParams);
      this.changeFilters()
    } else {
      this.tableReciclagem.controls.filters.patchValue(this.filterHelpers.getFormFilterValues(this.formFilter, true));
      this.changeFilters()
    }
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.tableLixoZero?.destroy()
    this.tableReciclagem?.destroy()
  }

  /**#################################################
   * ## FILTROS
   #################################################*/

    // requests dos filtros, foram criados dessa forma, pois são chamados em duas
    // condicionais diferentes. E dessa maneira podemos garantir um fluxo correto e editável de dados.
    // alem disso cada request deve tratar os dados de parametros da maneira que o back espera.
  private requestsFilters = {
    geradores: () => {
      if (!!this.controlles.handleHequestsFilters['geradores']) {
        this.controlles.handleHequestsFilters['geradores']?.unsubscribe();
        this.controlles.handleHequestsFilters['geradores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['operadores'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['geradores'] = this.filterHelpers.loaderList2('geradores', params, list => this.controlles.filtersOptions['geradores'] = list)
    },
    residuos: () => {
      if (!!this.controlles.handleHequestsFilters['tipoResiduos']) {
        this.controlles.handleHequestsFilters['tipoResiduos']?.unsubscribe();
        this.controlles.handleHequestsFilters['tipoResiduos'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['tipoResiduos'] = this.filterHelpers.loaderList2('residuos', params, list => this.controlles.filtersOptions['tipoResiduos'] = list)
    },
    transportadores: () => {
      if (!!this.controlles.handleHequestsFilters['transportadores']) {
        this.controlles.handleHequestsFilters['transportadores']?.unsubscribe();
        this.controlles.handleHequestsFilters['transportadores'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)
      if (params['galpao']) {
        params['galpaoId'] = params['galpao'];
        delete params['galpao'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('transportadores', params, list => this.controlles.filtersOptions['operadores'] = list)
    },
    estados: () => {
      if (!!this.controlles.handleHequestsFilters['estados']) {
        this.controlles.handleHequestsFilters['estados']?.unsubscribe();
        this.controlles.handleHequestsFilters['estados'] = undefined;
      }
      const {...params} = this.filterHelpers.getFormFilterValues(this.formFilter, true)

      if (params['operadores']) {
        params['galpaoId'] = params['operadores'];
        delete params['operadores'];
      }
      if (params['tipoResiduos']) {
        params['tipoLixoId'] = params['tipoResiduos'];
        delete params['tipoResiduos'];
      }
      this.controlles.handleHequestsFilters['transportadores'] = this.filterHelpers.loaderList2('estados', params, list => this.controlles.filtersOptions['estados'] = list)
    },
  }

  loaderFilterList(filterList?: string) {
    if (!filterList) {
      if (this.controlles.permissionFilters.includes('tipoResiduos')) this.requestsFilters.residuos()
      if (this.controlles.permissionFilters.includes('estados')) this.requestsFilters.estados()
      if (this.controlles.permissionFilters.includes('geradores')) this.requestsFilters.geradores()

      return
    }

    const mapAtualisacoes: { [key: string]: string[] } = {
      geradores: ['tipoResiduos', 'estados'],
      tipoResiduos: ['geradores', 'estados'],
      estados: ['geradores', 'tipoResiduos'],
    }

    if (!this.controlles.permissionFilters.includes(filterList)) return;
    if (!(filterList in mapAtualisacoes)) return;

    if (mapAtualisacoes[filterList].includes('geradores')) {
      this.requestsFilters.geradores()
    }

    if (mapAtualisacoes[filterList].includes('tipoResiduos')) {
      this.requestsFilters.residuos()
    }

    if (mapAtualisacoes[filterList].includes('estados')) {
      this.requestsFilters.estados()
    }
  }

  loaderFavoritos() {
    const paramsLoaderFavoritos:{[key:string]: any} = this.filterHelpers.getFormFilterValues(this.formFilter, true);

    this.filterHelpers.loaderListFavoritos('ranking', paramsLoaderFavoritos, list => this.controlles.filtersOptions['favoritos'] = list)
  }

  changeFilters(nameFilter?: string) {
    const queryParams = this.filterHelpers.getFormFilterValues(this.formFilter);

    this.router.navigate(
      [],
      {
        relativeTo: this.activatedRoute,
        queryParams: {rankings: Object.keys(queryParams).length > 0 ? JSON.stringify(queryParams) : null},
        queryParamsHandling: 'merge',
      }
    ).then();
    // if (!!nameFilter) this.loaderFilterList(nameFilter)
    this.loaderFilterList(nameFilter)
  }

  clearFilters() {
    this.formFilter.patchValue({
      favoritos: '',
      geradores: [],
      dataInicio: '',
      dataFim: '',
      residuos: [],
      estados: [],
      perfilUnidade: 'CONDOMINIO',
    })
    this.filterHelpers.clearRequests(this.controlles.handleHequestsFilters)
    this.changeFilters()
  }

  propagarFavorito() {
    const favoritoValues = this.favoritos.selectedList[0]

    if (!favoritoValues) return;

    const newValue: {[key:string]: any} = {
      geradores: (favoritoValues?.clientes || []).map((item: any) => item.id),
      galpao: (favoritoValues?.galpoes || []).map((item: any) => item.id),
      motoristas: (favoritoValues?.motoristas || []).map((item: any) => item.id),
      tipoResiduos: (favoritoValues?.tipoLixos || []).map((item: any) => item.id),
      dataInicio: favoritoValues?.dataInicio,
      dataFim: favoritoValues?.dataFim,
      estados: (favoritoValues?.estados || []).map((item: any) => item.uf),
    }

    if (favoritoValues?.perfilUnidade) {
      newValue['perfilUnidade'] = favoritoValues?.perfilUnidade;
    }

    this.formFilter.patchValue(newValue)

    this.changeFilters()
  }

  /**#################################################
   * ## DADOS
   #################################################*/

  initLoaderData() {
    if (JSON.stringify(this.controlles.lastFilter) == JSON.stringify(this.filterHelpers.getFormFilterValues(this.formFilter))) {
      return;
    }
    if (Object.keys(this.filterHelpers.getFormFilterValues(this.formFilter)).length <= 0) {
      this.clearData();
      return
    }

    let queryParams = this.filterHelpers.getFormFilterValues(this.formFilter, true);
    this.controlles.lastFilter = this.filterHelpers.getFormFilterValues(this.formFilter);

    queryParams = {
      favoritos: '',
      geradores: [],
      dataInicio: '',
      dataFim: '',
      residuos: [],
      estados: [],
      perfilUnidade: 'CONDOMINIO',
      ...queryParams
    }

    if ('geradores' in queryParams) {
      queryParams['unidades'] = queryParams['geradores'];
      delete queryParams['geradores'];
    }

    this.tableLixoZero.controls.filters.patchValue(queryParams);
    this.tableReciclagem.controls.filters.patchValue(queryParams);
  }

  clearData() {
    this.tableLixoZero.controls.filters.patchValue({
      favoritos: '',
      geradores: [],
      dataInicio: '',
      dataFim: '',
      residuos: [],
      estados: [],
    })
  }

  /**#################################################
   * ## FUNÇÕES
   #################################################*/

  verifyEnablePrint(table: 'lixoZero' | 'taxaReciclagem') {
    const filters = this.filterHelpers.getFormFilterValues(this.formFilter)

    if (table == 'lixoZero') {
      if (filters['perfilUnidade']) {
        delete filters['perfilUnidade']

        return Object.keys(filters).length > 0
      }
    }

    return Object.keys(filters).length > 1
  }

  getPrintParams(table: 'lixoZero' | 'taxaReciclagem', getPagination: boolean = false) {
    let rankings = JSON.parse(this.activatedRoute.snapshot.queryParams['rankings'])
    const result = {
      rankings: ''
    }
    let rankingsResult: any = {};

    rankingsResult = {
      ...rankings,
    }
    if (getPagination) {
      rankingsResult['pagination'] = this[table == 'lixoZero' ? 'tableLixoZero' : 'tableReciclagem'].controls.pagination.get()
    } else {
      rankingsResult['unpaged'] = true;
    }

    result.rankings = JSON.stringify(rankingsResult)

    // if (table == 'lixoZero') {
    //
    //   rankingsResult = {
    //     ...rankings,
    //   }
    //   if (getPagination) {
    //     rankingsResult['pagination'] = this.tableLixoZero.controls.pagination.get()
    //   } else {
    //     rankingsResult['unpaged'] = true;
    //   }
    //
    //   result.rankings = JSON.stringify(rankingsResult)
    //
    //
    // } else {
    //   result.rankings = JSON.stringify({
    //     ...rankings,
    //     ...(
    //       getPagination ? { pagination: this.tableReciclagem.controls.pagination.get() } : {}
    //     )
    //   })
    // }

    return result;
  }


  downloadExcel(table: 'lixoZero' | 'taxaReciclagem') {
    let params = this.filterHelpers.getFormFilterValues(this.formFilter, true);
    if ('geradores' in params) {
      params['unidades'] = params['geradores'];
      delete params['geradores'];
    }

    const config = {
      lixoZero: {
        endpoint: 'coletas/ranking-coleta/aterro-zero/planilha',
        nameFile: 'ranking-coleta-atero-zero.xlsx'
      },
      taxaReciclagem: {
        endpoint: 'coletas/ranking-coleta/taxa-reciclagem/planilha',
        nameFile: 'ranking-coleta-taxa-reciclagem.xlsx'
      },
    }

    this.http
      .get(config[table].endpoint, {params})
      .subscribe((resp) => {
        this.files.downloadBase64(resp.data, config[table].nameFile);
      });
  }

  print(table: 'lixoZero' | 'taxaReciclagem') {
    Swal.fire({
      icon: 'question',
      text: 'Imprimir paginação atual ou todos os registros?',
      cancelButtonText: 'Todos os registros',
      showCancelButton: true,
      confirmButtonText: 'Registros da tela'
    }).then(conf => {
      this.dataDashboard[table == 'lixoZero' ? 'queryPrintLixoZero' : 'queryPrintTaxaReciclagem'] = this.getPrintParams(table, conf.isConfirmed);

      setTimeout(() => document.getElementById('link-print-'+table)?.click(), 100)
    })
  }
}
