import {Component, Input, OnInit, Optional, Self} from '@angular/core';
import {AbstractDgAutocomplete} from "@datagrupo/dg-ng-util";
import {NgControl} from "@angular/forms";

@Component({
  selector: 'default-autocomplete-input',
  templateUrl: './default-autocomplete-input.component.html',
  styleUrls: ['./default-autocomplete-input.component.scss']
})
export class DefaultAutocompleteInputComponent extends AbstractDgAutocomplete {

  // @Input() override showCheckInputOption: boolean | undefined;
  // @Input() override showSelectedOption: boolean | undefined;
  @Input() loaderData: boolean | undefined;

  constructor(@Optional() @Self() public ngControl: NgControl) {
    super(ngControl)
    this.showSelectedOption = true
  }
}
