import {
  AbstractEntity,
  DgFilter_autoComplete,
  DgFilter_input,
  DgFilter_select,
  DgTableColumn
} from "@datagrupo/dg-crud";
import {environment} from "../../../../../environments/environment";
import {ClientesEntity} from "../clientes/clientes.entity";
import {autoCompleteClientesOutPut} from "../../../../helpers/auto-complete/clientes-autocomplete.helper";

export const tiposUsuarios = {
  SINDICO: "Gestor",
  CONSELHO: "Conselho/Administrativo",
  MORADOR: "Usuário",
  PORTEIRO: "Operador",
  FINANCEIRO: "Financeiro"
};
export const objTiposUsuarios = [
  {key: 'SINDICO', value: "Gestor"},
  {key: 'CONSELHO', value: "Administrativo"},
  {key: 'MORADOR', value: "Usuário"},
  {key: 'PORTEIRO', value: "Operador"},
  {key: 'FINANCEIRO', value: "Financeiro"},
];

export enum emunTiposUsuarios {
  SINDICO = "Gestor",
  CONSELHO = "Administrativo",
  MORADOR = "Usuário",
  PORTEIRO = "Operador",
  FINANCEIRO = "Financeiro"
}

export class ClientesFuncionariosEntity extends AbstractEntity {

  constructor(
    id?: number | string,
    nome?: string,
    sobreNome?: string,
    cpf?: number | string,
    login?: string,
    email?: string,
    telefone?: string | number,
    tipoUsuario?: string,
    cliente?: ClientesEntity,
    nascimento?: string,
    contato?: boolean,
    //ultimo?: string
  ) {
    super();
    this.id = id;
    this.nome = nome;
    this.sobreNome = sobreNome;
    this.cpf = cpf;
    this.login = login;
    this.email = email;
    this.telefone = telefone;
    this.tipoUsuario = tipoUsuario;
    this.cliente = cliente;
    this.nascimento = nascimento;
    this.contato = contato;
    //this.ultimo = ultimo;
  }

  @DgFilter_autoComplete({colum: '4'}, {
    getUrl: environment.apiUrl + 'clientes/nomes',
    getServerData: "all",
    propertKey: 'id',
    propertValue: 'nome',
    nameParamFind: 'clienteId',
    nameParamFilter: 'nome',
    outputFormat: autoCompleteClientesOutPut

  })
  @DgTableColumn({
    columnName: 'Gerador',
    sort: true,
    resource: (cli: ClientesEntity) => !!cli?.nome ? cli.nome : '--'
  })
  public cliente: ClientesEntity | undefined

  @DgFilter_input('text', {colum: '4'})
  @DgTableColumn({columnName: 'Nome', sort: true})
  public nome: string | undefined

  @DgFilter_input('text', {colum: '4'})
  @DgTableColumn({columnName: 'Login', sort: true})
  public login: string | undefined

  @DgFilter_input('text', {colum: '4'})
  @DgTableColumn({columnName: 'Email', sort: true})
  public email: string | undefined

  @DgFilter_select({colum: '4', data: objTiposUsuarios, propertKey: 'key', propertValue: 'value'})
  @DgTableColumn({columnName: 'Tipo', resource: tiposUsuarios, sort: true})
  public tipoUsuario: string | undefined

  @DgTableColumn({
    columnName: 'Complemento', resource: (val: string | undefined) => {
      return !!val ? val : '--';
    }
  })
  public complemento: string | undefined


  public sobreNome: string | undefined
  public cpf: number | string | undefined
  public telefone: string | number | undefined
  public nascimento: string | undefined
  public contato: boolean | undefined
}
